import type { ReactNode } from 'react';
import { Route } from 'react-router-dom';

import type { RouteType } from './types';

export const drawRoutes = (
  routes: RouteType[],
  company: Record<string, unknown> | null,
  componentProps: Record<string, unknown> | null,
): ReactNode[] => {
  return routes.map((route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        render={(props) => (
          <route.component
            {...componentProps}
            {...props}
            company={company}
            routes={route.routes}
            currentRoute={route}
          />
        )}
      />
    );
  });
};
