/**
 * @generated SignedSource<<ec05218a73a71bd736fba0db81b74b39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderButtonTheme_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FeatureTemp_user">;
  readonly " $fragmentType": "HeaderButtonTheme_user";
};
export type HeaderButtonTheme_user$key = {
  readonly " $data"?: HeaderButtonTheme_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderButtonTheme_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderButtonTheme_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeatureTemp_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5709f6174a445ec2dff2e2775ea3e696";

export default node;
