type Props = {
  width?: string;
  height?: string;
  showText?: boolean;
};

export default ({ width, height = '40px', showText = true }: Props) => {
  const fill1 = showText ? '#1F6D61' : '#fff';
  const fill2 = showText ? '#308E83' : '#fff';
  const fill3 = showText ? '#4AB7A8' : '#fff';
  const fill4 = showText ? '#96969A' : '#fff';

  return (
    <svg
      version='1.1'
      id='OpenPixLogo'
      data-testid='OpenPixLogo'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width={width}
      height={height}
      viewBox='0 0 670.49 140.22'
    >
      <g fill={fill4} fillRule='nonzero' id='open'>
        <polygon points='469.7,34.9 469.6,35.2 469.8,35' />
        <path
          d='M264.8,59.4c0,8.5-1.2,15.9-3.7,22.4c-2.5,6.5-5.8,11.9-10,16.2c-4.2,4.3-9.1,7.6-14.7,9.8
                c-5.6,2.2-11.5,3.3-17.8,3.3c-6.3,0-12.2-1.1-17.8-3.3c-5.6-2.2-10.5-5.4-14.7-9.8c-4.2-4.3-7.6-9.7-10-16.2
                c-2.5-6.5-3.7-13.9-3.7-22.4c0-8.5,1.2-15.9,3.7-22.3c2.5-6.4,5.8-11.8,10-16.1c4.2-4.3,9.1-7.6,14.7-9.8
                c5.6-2.2,11.5-3.3,17.8-3.3c6.3,0,12.2,1.1,17.8,3.3c5.6,2.2,10.5,5.4,14.7,9.8c4.2,4.3,7.6,9.7,10,16.1
                C263.5,43.5,264.8,51,264.8,59.4L264.8,59.4z M254.5,59.4c0-6.6-0.9-12.6-2.6-17.9c-1.7-5.3-4.1-9.8-7.3-13.5
                c-3.1-3.7-6.9-6.5-11.3-8.5c-4.4-1.9-9.4-2.9-14.9-2.9c-5.5,0-10.5,1-14.9,2.9c-4.4,1.9-8.2,4.8-11.3,8.5
                c-3.1,3.7-5.6,8.2-7.3,13.5c-1.7,5.3-2.6,11.2-2.6,17.9c0,6.7,0.9,12.6,2.6,17.9c1.7,5.3,4.1,9.8,7.3,13.5
                c3.1,3.7,6.9,6.6,11.3,8.5c4.4,2,9.4,3,14.9,3c5.5,0,10.5-1,14.9-3c4.4-2,8.2-4.8,11.3-8.5c3.1-3.7,5.6-8.2,7.3-13.5
                C253.6,72,254.5,66.1,254.5,59.4L254.5,59.4z'
        />
        <path
          d='M289.7,135.2h-9.3V37.8c2.8-0.9,6.3-1.9,10.5-2.7c4.3-0.9,9.7-1.3,16.2-1.3c5.4,0,10.3,0.9,14.7,2.6
                c4.4,1.8,8.2,4.3,11.4,7.6c3.2,3.3,5.6,7.4,7.4,12.1c1.8,4.7,2.6,10.1,2.6,16.1c0,5.6-0.7,10.8-2.2,15.5
                c-1.5,4.7-3.6,8.7-6.5,12.1c-2.8,3.4-6.3,6-10.5,7.9c-4.1,1.9-8.9,2.8-14.2,2.8c-4.8,0-9-0.7-12.6-2.1c-3.6-1.4-6.2-2.7-7.8-3.9
                V135.2z M289.7,95.6c0.9,0.7,1.9,1.4,3.2,2.1c1.3,0.8,2.8,1.5,4.5,2.1c1.7,0.7,3.6,1.2,5.6,1.6c2,0.4,4.1,0.6,6.3,0.6
                c4.6,0,8.4-0.8,11.5-2.4c3.1-1.6,5.6-3.7,7.5-6.4c1.9-2.7,3.3-5.9,4.1-9.5c0.9-3.7,1.3-7.5,1.3-11.6c0-9.7-2.4-17.1-7.3-22.3
                c-4.8-5.2-11.3-7.8-19.2-7.8c-4.6,0-8.2,0.2-11,0.6c-2.8,0.4-4.9,0.9-6.3,1.4V95.6z'
        />
        <path
          d='M353.7,72c0-6.5,0.9-12.1,2.8-16.9c1.9-4.8,4.3-8.8,7.3-12c3-3.2,6.4-5.6,10.3-7.2c3.8-1.6,7.8-2.4,11.9-2.4
                c9,0,16.2,2.9,21.6,8.8c5.4,5.9,8.1,15,8.1,27.2c0,0.8,0,1.5-0.1,2.3c0,0.8-0.1,1.5-0.2,2.1h-51.7c0.3,9,2.5,16,6.7,20.8
                c4.2,4.8,10.8,7.3,19.9,7.3c5,0,9-0.5,12-1.4c2.9-0.9,5.1-1.8,6.4-2.4l1.7,8c-1.3,0.8-3.8,1.7-7.5,2.7c-3.7,1-8,1.6-12.9,1.6
                c-6.5,0-12-1-16.5-2.9c-4.6-1.9-8.3-4.6-11.3-8c-2.9-3.4-5.1-7.5-6.4-12.2C354.3,82.7,353.7,77.6,353.7,72L353.7,72z M406,66.1
                c-0.2-7.7-2-13.7-5.4-18c-3.4-4.3-8.3-6.4-14.5-6.4c-3.3,0-6.3,0.7-8.9,2c-2.6,1.3-4.9,3.1-6.8,5.3c-1.9,2.2-3.5,4.8-4.6,7.8
                c-1.1,2.9-1.7,6-1.9,9.3H406z'
        />
        <path
          d='M430.7,37.8c2.8-0.8,6.5-1.6,11.1-2.6c4.7-0.9,10.4-1.4,17.1-1.4c5.6,0,10.3,0.8,14,2.4
                c3.8,1.6,6.7,3.9,9,6.9c2.2,3,3.8,6.6,4.8,10.8c0.9,4.2,1.4,8.8,1.4,13.8v41.2h-9.3V70.7c0-5.2-0.4-9.6-1.1-13.3
                c-0.7-3.6-1.9-6.6-3.6-8.8c-1.7-2.3-3.8-3.9-6.6-4.9c-2.7-1-6.1-1.5-10.2-1.5c-4.4,0-8.1,0.2-11.3,0.7c-3.2,0.5-5.3,0.9-6.2,1.3
                v64.7h-9.3V37.8z'
        />
      </g>

      <g fill={fill3} fillRule='nonzero' id='pix'>
        <path
          d='M532.1,19.1c12.8,0,22.3,2.4,28.5,7.3c6.3,4.9,9.4,11.8,9.4,20.7c0,5.1-0.9,9.5-2.7,13.1
                    c-1.8,3.6-4.4,6.5-7.8,8.7c-3.4,2.2-7.5,3.8-12.4,4.8c-4.9,1-10.4,1.5-16.6,1.5h-12.2v34.9h-8.9V21.6c3.1-0.9,6.8-1.5,11-1.9
                    C524.6,19.3,528.5,19.1,532.1,19.1z M532.5,26.8c-3.3,0-6.1,0.1-8.4,0.3c-2.3,0.2-4.3,0.4-5.9,0.6v39.9h11.2
                    c4.8,0,9.1-0.3,13-0.8c3.9-0.6,7.2-1.6,9.9-3.1c2.7-1.5,4.8-3.6,6.3-6.3c1.5-2.7,2.2-6.1,2.2-10.3c0-4-0.8-7.3-2.4-9.9
                    c-1.6-2.6-3.7-4.7-6.4-6.2c-2.6-1.5-5.7-2.6-9.1-3.2C539.6,27.1,536.1,26.8,532.5,26.8z'
        />
        <path
          d='M599,22.5c0,2-0.6,3.6-1.8,4.8c-1.2,1.2-2.7,1.8-4.4,1.8c-1.7,0-3.2-0.6-4.4-1.8c-1.2-1.2-1.8-2.8-1.8-4.8
                    c0-2,0.6-3.6,1.8-4.8c1.2-1.2,2.7-1.8,4.4-1.8c1.7,0,3.2,0.6,4.4,1.8C598.3,19,599,20.5,599,22.5z M597,110.2h-8.5V42.9h8.5
                    V110.2z'
        />
        <path
          d='M643,81.7c-1.9,2.3-3.8,4.7-5.7,7.2c-1.9,2.5-3.7,5-5.5,7.4c-1.8,2.4-3.4,4.9-4.9,7.4
                    c-1.5,2.5-2.7,4.7-3.7,6.6h-8.8c3.6-6.6,7.5-12.8,11.6-18.4c4.1-5.6,8.2-11.1,12.5-16.5l-22.8-32.4h9.9l17.6,25.5l17.6-25.5h9.2
                    l-22.3,32c1.9,2.4,3.9,4.9,6.1,7.7c2.2,2.8,4.3,5.7,6.5,8.7c2.2,3,4.2,6.1,6.3,9.3c2,3.2,3.9,6.4,5.6,9.6h-9.1
                    c-1-1.9-2.3-4-3.8-6.3c-1.5-2.3-3.1-4.7-4.9-7.2c-1.8-2.5-3.7-5-5.6-7.6C646.8,86.5,644.9,84,643,81.7z'
        />
      </g>
      <g>
        <path
          fill={fill1}
          d='M134.8,93.9c-0.8-1.2-1.9-2.2-3.3-3l-11.4-6.2l-7.9-4.3l-7.9,4.3l7.9,4.3l9.9,5.4c2.9,1.6,2.9,5.1,0,6.7
                l-43.8,24c-3.5,1.9-8.2,1.9-11.7,0l-43.8-24.1c-2.9-1.6-2.9-5.1,0-6.7l9.8-5.4l7.9-4.3l-7.9-4.3l-7.9,4.3l-11.4,6.2
                c-2.8,1.6-4.5,4.1-4.5,6.9c0,1.4,0.4,2.7,1.2,3.8c0.8,1.2,1.9,2.2,3.3,3l53.3,29.2c3.5,1.9,8.2,1.9,11.7,0l53.2-29.2
                c2.8-1.6,4.5-4.1,4.5-6.9C136,96.4,135.6,95.1,134.8,93.9z'
        />
        <path
          fill={fill2}
          d='M134.8,67.7c-0.8-1.2-1.9-2.2-3.3-3l-11.4-6.2l-7.9-4.3l-7.9,4.3l7.9,4.3l9.9,5.4c2.9,1.6,2.9,5.1,0,6.7
                l-9.8,5.4l-7.9,4.3L78.3,99c-3.5,1.9-8.2,1.9-11.7,0L40.5,84.7l-7.9-4.3l-9.9-5.4c-2.9-1.6-2.9-5.1,0-6.7l9.8-5.4l7.9-4.3
                l-7.9-4.3l-7.9,4.3l-11.4,6.2c-2.8,1.6-4.5,4.1-4.5,6.9c0,1.4,0.4,2.7,1.2,3.9c0.8,1.2,1.9,2.2,3.3,3l11.4,6.2l7.9,4.3l34,18.6
                c3.5,1.9,8.2,1.9,11.7,0L112.2,89l7.9-4.3l11.4-6.2c2.8-1.6,4.5-4.1,4.5-6.9C136,70.2,135.6,68.9,134.8,67.7z'
        />
        <path
          fill={fill3}
          d='M134.8,41.6c-0.8-1.2-1.9-2.2-3.3-3L78.3,9.5c-3.5-1.9-8.2-1.9-11.7,0L13.3,38.6c-2.8,1.6-4.5,4.1-4.5,6.9
                c0,1.4,0.4,2.7,1.2,3.8c0.8,1.2,1.9,2.2,3.3,3l11.4,6.2l7.9,4.3l34,18.6c3.5,1.9,8.2,1.9,11.7,0l33.9-18.6l7.9-4.3l11.4-6.2
                c2.8-1.6,4.5-4.1,4.5-6.9C136,44.1,135.6,42.8,134.8,41.6z M122.1,48.8l-9.8,5.4l-7.9,4.3l-26,14.3c-3.5,1.9-8.2,1.9-11.7,0
                L40.5,58.5l-7.9-4.3l-9.9-5.4c-2.9-1.6-2.9-5.1,0-6.7l43.8-24c3.5-1.9,8.2-1.9,11.7,0l43.8,24.1C125,43.8,125,47.2,122.1,48.8z'
        />
        <g fill={fill1}>
          <path
            d='M65.8,44.1c-3.3-2-6.6-3.9-9.9-5.9c-1.2-0.7-2.4-1.1-3.8-1c-0.8-0.1-1.5,0.1-2.2,0.5
                        c-3.7,2.2-7.5,4.4-11.3,6.7c-1.4,0.8-1.4,2,0,2.8c3.7,2.1,7.4,4.1,11.1,6.3c2.2,1.3,4.4,1.1,6.6,0c3.2-1.8,6.3-3.7,9.5-5.5
                        C67.6,46.9,67.6,45.2,65.8,44.1z M58.4,46.4l-5.6,3.1c-0.2,0.1-0.5,0.1-0.8,0l-5.6-3.2c-0.5-0.3-0.5-1.1,0-1.4l5.7-3.2
                        c0.2-0.1,0.6-0.1,0.8,0l5.5,3.2C58.9,45.4,58.9,46.1,58.4,46.4z'
          />
          <path
            d='M86.3,56.4L74.1,49c-0.8-0.5-1.7-0.5-2.5,0L59,56.2c-0.9,0.5-0.9,1.8,0,2.3l11.3,6.6c1.5,0.8,3.2,0.8,4.7,0
                    l11.3-6.5C87.2,58.2,87.2,56.9,86.3,56.4z M78.9,58l-6,3.4c-0.2,0.1-0.4,0.1-0.6,0l-6-3.5c-0.4-0.2-0.4-0.8,0-1.1l5.8-3.3
                    c0.4-0.2,0.8-0.2,1.1,0L79,57C79.4,57.2,79.4,57.8,78.9,58z'
          />
          <path
            d='M86.6,33.1l-12.3-7.9c-0.8-0.5-1.8-0.5-2.6,0L59,32.3c-1.2,0.7-1.4,2.6-0.2,3.3l11.9,7c1.2,0.7,2.8,0.7,4,0
                    l11.8-6.4C87.7,35.5,87.7,33.8,86.6,33.1z M78.6,34.9l-5,2.7c-0.5,0.3-1.2,0.3-1.7,0l-5.1-3c-0.5-0.3-0.4-1.1,0.1-1.4l5.4-3
                    c0.3-0.2,0.8-0.2,1.1,0l5.3,3.4C79.1,33.9,79.1,34.6,78.6,34.9z'
          />
          <path
            d='M87.1,52.3l3.5,2.2c0.9,0.7,2.1,0.7,3.1,0.1l3.3-2c0.8-0.5,0.8-1.6,0-2.1l-4-2.5c-0.4-0.2-0.8-0.2-1.2,0
                    l-4.7,2.6C86.6,50.9,86.5,51.8,87.1,52.3z'
          />
          <path
            d='M96.7,46.7l3.4,2.2c0.9,0.6,2.1,0.6,3,0.1l3.6-2.2c0.5-0.3,0.6-1.1,0-1.5l-3.7-2.3c-0.7-0.5-1.7-0.5-2.4,0
                    l-3.8,2.2C96.2,45.5,96.1,46.3,96.7,46.7z'
          />
          <path
            d='M87.6,41l4.2,2.9c0.7,0.5,1.5,0.5,2.2,0l4.3-2.8c0.4-0.3,0.4-0.8,0-1.1l-4.5-2.8c-0.6-0.4-1.3-0.4-1.9,0
                    l-4.3,2.6C87.2,40.2,87.2,40.7,87.6,41z'
          />
        </g>
      </g>
    </svg>
  );
};
