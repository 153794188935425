import { useTranslation } from 'react-i18next';

import { SwipeableDialogModal, SwipeableDialogTitle } from '@woovi/ui';

import { AccountActivateTutorialModalContent } from './AccountActivateTutorialModalContent';

import type { ReactNode } from 'react';

export const AccountActivateTutorialModal = (): ReactNode => {
  const { t } = useTranslation();

  return (
    <SwipeableDialogModal>
      <SwipeableDialogTitle>
        {t(
          'To access this feature, complete your details for an account upgrade',
        )}
      </SwipeableDialogTitle>
      <AccountActivateTutorialModalContent />
    </SwipeableDialogModal>
  );
};
