import { useMemo } from 'react';
import { useSubscription } from 'react-relay';
import type { GraphQLSubscriptionConfig } from 'relay-runtime';

import type {
  ChargeUpdatedStatusSubscription,
  ChargeUpdatedStatusSubscription$data,
  ChargeUpdatedStatusSubscription$variables,
} from './__generated__/ChargeUpdatedStatusSubscription.graphql';
import { ChargeUpdatedStatus } from './ChargeUpdatedStatusSubscription';

export const useChargeUpdatedStatusSubscription = (
  variables: ChargeUpdatedStatusSubscription$variables,
  onNext?: (data: ChargeUpdatedStatusSubscription$data) => void,
) => {
  const getOnNext = () => {
    if (!onNext) {
      return {};
    }

    return {
      onNext,
    };
  };

  const newMessageConfig = useMemo<
    GraphQLSubscriptionConfig<ChargeUpdatedStatusSubscription>
  >(
    () => ({
      subscription: ChargeUpdatedStatus,
      variables,
      ...getOnNext(),
    }),
    [variables],
  );

  useSubscription(newMessageConfig);
};
