import CircularProgress from '@mui/material/CircularProgress';
import type { GridValidRowModel, GridRowParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

import type { DataGridProps } from '@woovi/ui';
import { CardListVirtualized, DataGrid } from '@woovi/ui';

import type { ReactNode } from 'react';

const addMinWidth = (columns) => {
  return columns.map((column) => {
    return {
      minWidth: 80,
      ...column,
    };
  });
};

type TableConnection<T extends GridValidRowModel> = {
  readonly edges: ReadonlyArray<{
    readonly node: T | null;
  } | null>;
};

export type TableInfiniteScrollProps<T extends GridValidRowModel> = {
  connection: TableConnection<T>;
  pagination?: {
    loadNext: () => unknown;
    hasNext: boolean;
  };
  isLoadingNext?: boolean;
  displayCardList?: boolean;
  enableCheckboxSelection?: boolean;
  isRefetching?: boolean;
  onRowClick?: (params: GridRowParams<T>) => void;
  maxLines?: number;
  noItemsLabel?: string;
  rowHref?: string;
  cardListDefaultHeight?: string;
} & Omit<DataGridProps, 'rows' | 'pagination'>;

const TableInfiniteScroll = <T extends GridValidRowModel>(
  {
    connection,
    pagination,
    isLoadingNext = false,
    displayCardList = false,
    enableCheckboxSelection = false,
    isRefetching = false,
    onRowClick,
    isRowSelectable,
    getDetailPanelContent,
    getDetailPanelHeight,
    rowSelectionModel,
    onRowSelectionModelChange,
    onSortModelChange,
    onCellClick,
    maxLines,
    sortModel,
    noItemsLabel,
    sortingMode,
    rowHref,
    experimentalFeatures,
    tableName,
    initialState,
    getRowId,
    disableSelectAllCheckbox,
    cardListDefaultHeight,
    ...rest
  }: TableInfiniteScrollProps<T>,
): ReactNode => {
  const rows = useMemo(
    () => connection?.edges?.map(({ node }) => node) ?? [],
    [connection?.edges],
  );

  const columns = addMinWidth(rest.columns);

  if (isRefetching) {
    return <CircularProgress />;
  }

  const fetchNewPage = () => {
    if (!pagination) {
      return;
    }

    if (isLoadingNext) {
      return;
    }

    if (!pagination.hasNext) {
      return;
    }

    pagination.loadNext && pagination.loadNext();
  };

  if (displayCardList) {
    return (
      <CardListVirtualized
        columns={columns}
        data={rows}
        cardListDefaultHeight={cardListDefaultHeight}
        onScrollToBottom={fetchNewPage}
        onCardClick={onRowClick}
        noItemsLabel={noItemsLabel}
      />
    );
  }

  return (
    <DataGrid
      tableName={tableName}
      columns={columns}
      rows={rows}
      getEstimatedRowHeight={() => 128}
      getRowHeight={() => 'auto'}
      loading={isLoadingNext}
      onRowsScrollEnd={fetchNewPage}
      onRowClick={onRowClick}
      onCellClick={onCellClick}
      isRowSelectable={isRowSelectable}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={getDetailPanelHeight}
      checkboxSelection={enableCheckboxSelection}
      disableSelectAllCheckbox={disableSelectAllCheckbox}
      maxLines={maxLines}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      onSortModelChange={onSortModelChange}
      sortModel={sortModel}
      sortingMode={sortingMode}
      rowHref={rowHref}
      experimentalFeatures={experimentalFeatures}
      initialState={initialState}
      getRowId={getRowId}
    />
  );
};

export default TableInfiniteScroll;
