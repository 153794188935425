import type { SxProps } from '@mui/material';
import _CardActions from '@mui/material/CardActions';
import type { ReactNode } from 'react';

type CardActionsProps = {
  sx?: SxProps;
  children: ReactNode;
};

const CardActions = ({ children, sx = {} }: CardActionsProps): ReactNode => {
  return (
    <_CardActions
      sx={{
        justifyContent: 'flex-end',
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
    >
      {children}
    </_CardActions>
  );
};

export default CardActions;
