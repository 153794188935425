import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/clickpix';

export const clickpixRoutes: RouteType[] = [
  {
    name: 'clickpix',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('ClickPix'),
    label: ({ t }) => t('ClickPix'),
    component: loadable(
      () => import('../../components/clickpix/ClickPixHeader'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CLICK_PIX],
    routes: [
      {
        name: 'clickpix.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () => import('../../components/clickpix/tutorial/ClickPixTutorial'),
        ),
      },
    ],
  },
  {
    name: 'clickpix.create',
    path: `${PATH}/create`,
    labelHeader: ({ t }) => t('Create ClickPix Button'),
    label: ({ t }) => t('Create ClickPix Button'),
    component: loadable(
      () => import('../../components/clickpix/create/ClickPixButtonCreate'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CLICK_PIX],
  },
];
