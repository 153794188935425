import { Notifications } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import type { Notification_query$key } from './__generated__/Notification_query.graphql';
import { NotificationMenu } from './NotificationMenu';

import type { ReactNode } from 'react';

type NotificationProps = {
  query: Notification_query$key;
};

export const Notification = (props: NotificationProps): ReactNode => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const query = useFragment<Notification_query$key>(
    graphql`
      fragment Notification_query on Query {
        ...NotificationMenu_query
      }
    `,
    props.query,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton sx={{ color: 'white' }} onClick={handleClick}>
        <Notifications />
      </IconButton>
      <NotificationMenu
        query={query}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};
