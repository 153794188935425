import type { LoadableComponent } from '@loadable/component';

import {
  getRouteFromPath as getRouteFromPathRouter,
  getRouteTo,
  hasMatchedLegacy as hasMatchedLegacyRouter,
  safeGoBackRouter,
} from '@woovi/router';

import { legacyRoutes } from './legacyRoutes';
import { getRoutes } from '../routes';

// TODO - this is bad, remove it
export * from '@woovi/router';
export const routeToMain = getRouteTo(getRoutes());
// TODO - add Main prefix to avoid collision of names
export const getRouteFromPath = getRouteFromPathRouter(getRoutes());
export const hasMatchedLegacy = hasMatchedLegacyRouter(legacyRoutes);
export const safeGoBack = safeGoBackRouter(routeToMain);

type LabelParams = {
  t: (value: string) => string;
  company: any;
};
export type RouteType = {
  type?: string;
  name: string;
  path: string;
  component: LoadableComponent<any> | React.ComponentType<any>;
  exact?: boolean;
  routes?: RouteType[];
  labelHeader?: string | (({ t, company }: LabelParams) => string);
  label?: string | (({ t, company }: LabelParams) => string); // label for Tabs
  modalTitle?: string | (({ t, company }: LabelParams) => string);
  requiredRoles?: string[];
  requiredFeatures?: string[] | { $and: string[] }[];
  /**
   * Used to add the dashed style to route
   */
  isTemp?: boolean;
  requiredProjectsTypes?: string[];
  from?: string;
  to?: string;
  buttons?: ({ t }: { t: any }) => [
    {
      label: string;
      link: string;
    },
  ];
};
