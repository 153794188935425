import type { TextFieldProps } from '@mui/material';
import { TextField as _TextField } from '@mui/material';
import type { ChangeEventHandler, ReactNode } from 'react';
import { useRifm } from 'rifm';
import styled from 'styled-components';
import type { SpaceProps } from 'styled-system';
import { space } from 'styled-system';

import { applyMask, clear } from '../mask/MaskUtils';

const TextField = styled(_TextField)`
  ${space}
`;

export type MaskNumberInputProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  maxLength: number;
  getMask: (value: string) => string;
} & TextFieldProps &
  SpaceProps;
const MaskNumberInput = (props: MaskNumberInputProps): ReactNode => {
  const { value, onChange, maxLength, getMask, ...rest } = props;

  const format = (value: string) => {
    const trimmedValue = value.slice(0, maxLength);

    const onlyDigits = clear(trimmedValue);

    const mask = getMask(onlyDigits);

    const valueWithMask = applyMask(onlyDigits, mask);

    return valueWithMask;
  };

  const rifm = useRifm({
    value,
    onChange: (value) => {
      const e = { target: { value } };

      onChange(e);
    },
    format,
    mask: maxLength <= value.length,
  });

  return (
    <TextField
      variant='outlined'
      value={rifm.value}
      onChange={rifm.onChange}
      {...rest}
    />
  );
};

export default MaskNumberInput;
