import { useTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Crisp } from 'crisp-sdk-web';
import { type ReactNode, useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';

import { MODULES } from '@woovi/roles';
import { onlyNumbers } from '@woovi/shared';

import type { useCrisp_user$key } from './__generated__/useCrisp_user.graphql';
import { usePermission } from '../../security/usePermission';

const userCrispFragment = graphql`
  fragment useCrisp_user on User @inline {
    id
    name
    email
    company {
      nameFriendly
      features
    }
    phoneNumbers
    ...usePermission_user   
  }
`;

type UseCrisp = {
  userRef: useCrisp_user$key;
  crispId: string;
};

export const useCrisp = ({ userRef }: UseCrisp): ReactNode => {
  const theme = useTheme();
  const user = readInlineData(userCrispFragment, userRef);

  const { hasPermission } = usePermission(user);

  const hasChatwoot = hasPermission([MODULES.CHATWOOT]);

  if (!hasChatwoot) {
    Crisp.configure(process.env.CRISP_CHAT_ID, {
      autoload: false,
    });
  }

  const getUserPhone = (): string | null => {
    if (user.phoneNumbers && user.phoneNumbers.length > 0) {
      return user.phoneNumbers[0];
    }

    return null;
  };

  useEffect((): void => {
    if (!user) {
      return;
    }

    if (!process.env.CRISP_CHAT_ID) {
      // eslint-disable-next-line no-console
      console.log("You can't load Crisp because doesn't have CRISP_CHAT_ID");

      return;
    }

    if (hasChatwoot) {
      return;
    }

    Crisp.load();

    // TODO: While don't merging the PR: https://github.com/crisp-im/crisp-sdk-web/pull/2
    // Add the oldest way to set "safe mode".``
    window.$crisp.push(['safe', true]);

    Crisp.setTokenId(`${user.id}`);

    if (!Crisp.isCrispInjected()) {
      Sentry.captureException(new Error('Crisp not loaded'));

      return;
    }

    const { name, email, company } = user;
    const phone = getUserPhone();

    if (email) {
      Crisp.user.setEmail(email);
    }

    if (name) {
      Crisp.user.setNickname(name);
    }

    if (phone) {
      Crisp.user.setPhone(onlyNumbers(phone));
    }

    if (company?.nameFriendly) {
      Crisp.user.setCompany(company.nameFriendly);
    }

    Crisp.setZIndex(theme.zIndex.fab);
  }, [user]);
};
