import { isCNPJ, isCPFOrCNPJ } from 'brazilian-values';
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from 'libphonenumber-js';
import * as yup from 'yup';

import { validateStrongPasswordValue } from './validateStrongPasswordValue';
import { isSafeCPF } from '../isSafeCPF';

declare module 'yup' {
  interface StringSchema {
    isValidPhoneNumber(message: string): this;
    taxID(message: string): this;
    strongPassword(message: string): this;
    website(message: string): this;
  }
}

export function registerYupValidations() {
  yup.addMethod(yup.string, 'cnpj', function () {
    return this.test('cnpj', 'CNPJ inválido', (value) =>
      value ? isCNPJ(value) : true,
    );
  });

  const websiteRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

  yup.addMethod(yup.string, 'website', function () {
    return this.test('website', 'Website inválido', (value) =>
      value ? websiteRegex.test(value) : true,
    );
  });

  const subdomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9.-]+[a-zA-Z0-9]$/;

  yup.addMethod(yup.string, 'subdomain', function () {
    return this.test('subdomain', 'Subdomínio inválido', (value) =>
      subdomainRegex.test(value),
    );
  });

  yup.addMethod(yup.string, 'cpf', function () {
    return this.test('cpf', 'CPF inválido', (value) =>
      value ? isSafeCPF(value) : true,
    );
  });

  yup.addMethod(yup.string, 'taxID', function (message: string) {
    return this.test('taxID', message, (value) => {
      if (!value) {
        return true;
      }

      return isCPFOrCNPJ(value);
    });
  });

  yup.addMethod(yup.string, 'isValidPhoneNumber', function (errorMessage) {
    return this.test(
      'isValidPhoneNumber',
      errorMessage,
      (value, { options }) => {
        const initialValue = options.originalValue;

        if (!value) {
          return true;
        }

        if (initialValue < 17) {
          return errorMessage;
        }

        return isValidPhoneNumber(initialValue, 'BR');
      },
    ).transform(
      (value) => value && parsePhoneNumberFromString(value)?.formatNational(),
    );
  });

  yup.addMethod(yup.string, 'strongPassword', function strongPassword(message) {
    return this.test('strongPassword', message, (value, ctx) => {
      if (!value) {
        return true;
      }

      const { error, message: errorMessage } =
        validateStrongPasswordValue(value);

      if (!error) {
        return true;
      }

      return ctx.createError({ message: errorMessage, path: ctx.path });
    });
  });
}
