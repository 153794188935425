import { cellTypeBoolean } from './cellTypeBoolean';
import { cellTypeCurrency } from './cellTypeCurrency';
import { cellTypeDate } from './cellTypeDate';
import { cellTypeDatetime } from './cellTypeDatetime';
import { cellTypeDatetimeSeconds } from './cellTypeDatetimeSeconds';
import { cellTypePhone } from './cellTypePhone';
import { cellTypeTag } from './cellTypeTag';
import { cellTypeTaxID } from './cellTypeTaxID';
import { cellTypeTime } from './cellTypeTime';

export const columnTypes = {
  boolean: cellTypeBoolean,
  currency: cellTypeCurrency,
  dateAny: cellTypeDate,
  datetime: cellTypeDatetime,
  datetimeSeconds: cellTypeDatetimeSeconds,
  phone: cellTypePhone,
  tag: cellTypeTag(),
  taxID: cellTypeTaxID,
  time: cellTypeTime,
};
