import { IoPeopleOutline } from 'react-icons/io5';

import { routeToMain } from '../../../router/utils';

export const userAdmin = ({ t }) => {
  return [
    {
      label: t('Users'),
      icon: <IoPeopleOutline />,
      link: routeToMain('users.list'),
    },
  ];
};
