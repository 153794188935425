/**
 * @generated SignedSource<<9939cda51905146ed83ce1770c79e014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuCompanyInfo_user$data = {
  readonly company: {
    readonly name: string | null | undefined;
    readonly partner: {
      readonly name: string | null | undefined;
      readonly nameFriendly: string | null | undefined;
    } | null | undefined;
    readonly taxID: {
      readonly taxID: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "UserMenuCompanyInfo_user";
};
export type UserMenuCompanyInfo_user$key = {
  readonly " $data"?: UserMenuCompanyInfo_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuCompanyInfo_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuCompanyInfo_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxID",
          "kind": "LinkedField",
          "name": "taxID",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxID",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "partner",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameFriendly",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "91b2e1fdc4c92ce277d32edb28fc9410";

export default node;
