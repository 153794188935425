import ITimeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

import type { ReactNode } from 'react';

type TimelineProps = {
  children?: React.ReactNode;
};

export const Timeline = ({ children }: TimelineProps): ReactNode => {
  return (
    <ITimeline
      sx={{
        p: 0,
        m: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {children}
    </ITimeline>
  );
};
