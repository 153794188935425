export const getAppEventTime = <N>(node?: N | null) => {
  if (!node) {
    return '';
  }

  if (!node?.time) {
    return node?.createdAt;
  }

  return node?.time;
};
