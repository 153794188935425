import { graphql } from 'react-relay';

export const UserSetFlag = graphql`
  mutation UserSetFlagMutation($input: UserSetFlagInput!, $key: String) {
    UserSetFlag(input: $input) {
      error
      user {
        id
        name
        flags(key: $key) {
          key
          value
        }
      }
    }
  }
`;
