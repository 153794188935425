import { format as dateFnsFormat } from 'date-fns';
import {
  enUS as enLocale,
  es as esLocale,
  pt as ptLocale,
} from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en, es, ptBR } from '@woovi/i18n';

const locales = {
  pt: ptLocale,
  'pt-BR': ptLocale,
  'en-US': enLocale,
  en: enLocale,
  es: esLocale,
};

export const interpolationFormat = (value, format, lang) => {
  if (!value || value === '' || value === undefined || value === null) {
    return '';
  }

  if (!value.date) {
    return value;
  }

  const { date, fmt } = value;

  try {
    return dateFnsFormat(date, fmt, {
      locale: lang in locales ? locales[lang] : ptLocale,
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log('invalid date: ', date, fmt, err);

    return '';
  }
};

const i18nInterpolation = () => {
  i18n.use(LanguageDetector).init({
    resources: {
      en: {
        translation: en,
      },
      'pt-BR': {
        translation: ptBR,
      },
      es: {
        translation: es,
      },
    },
    fallbackLng: 'pt-BR',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: interpolationFormat,
    },
    react: {
      useSuspense: true,
    },
  });

  return i18n;
};

export default i18nInterpolation;
