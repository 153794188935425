import config from '../config';

export const firebaseConfig = {
  messagingSenderId: config.FIREBASE_MESSAGING_SEND_ID,
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  appId: config.FIREBASE_APP_ID,
};
