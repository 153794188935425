import { RelayEnvironmentProvider } from 'react-relay';

import { UnsupportedBrowserWarning, isUnSupportedBrowser } from '@woovi/ui';

import Providers from './Providers';
import { Environment } from './relay';
import ClientRouter from './router/ClientRouter';

import type { ReactNode } from 'react';

const Main = (): ReactNode => {
  if (isUnSupportedBrowser()) {
    return <UnsupportedBrowserWarning />;
  }

  return (
    <RelayEnvironmentProvider environment={Environment}>
      <Providers>
        <ClientRouter />
      </Providers>
    </RelayEnvironmentProvider>
  );
};

export default Main;
