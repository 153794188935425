import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from '../buttons/ActionButton';
import DangerButton from '../buttons/DangerButton';
import type { ShowDialogArgs } from './DialogContext';
import DialogContext from './DialogContext';

import type { ReactNode } from 'react';

type Props = {
  children: React.ReactNode;
};

export const useDefinedState = (initialState) => {
  const [value, setValue] = useState(initialState);

  const setDefinedValue = (newValue) => {
    if (newValue != null) {
      setValue(newValue);
    }
  };

  return [value, setDefinedValue];
};

const DialogProvider = ({ children }: Props): ReactNode => {
  const { t } = useTranslation();

  const onContinueFn = useRef(null);
  const onCloseFn = useRef(null);

  const [title, setTitle] = useDefinedState(null);
  const [message, setMessage] = useDefinedState('');
  const [isOpen, setIsOpen] = useDefinedState(false);
  const [rest, setRest] = useDefinedState({});

  const handleClose = () => {
    if (onCloseFn.current) {
      onCloseFn.current();
    }

    setIsOpen(false);
  };

  const handleDialogAgree = () => {
    if (onContinueFn.current) {
      onContinueFn.current();
    }

    setIsOpen(false);
  };

  const [action, setAction] = useDefinedState(
    <>
      <ActionButton
        variant={'outlined'}
        onClick={handleClose}
        data-testid={'btn-dialog-cancel'}
      >
        {t('Cancel')}
      </ActionButton>
      <DangerButton
        color='primary'
        onClick={handleDialogAgree}
        data-testid={'btn-dialog-confirm'}
      >
        {t('Confirm')}
      </DangerButton>
    </>,
  );

  const showDialog = (dialogArgs: ShowDialogArgs) => {
    const { title, message, onContinue, onClose, action, ...rest } = dialogArgs;

    if (onContinue) {
      onContinueFn.current = onContinue;
    }

    if (onClose) {
      onCloseFn.current = onClose;
    }

    const newActions = action || (
      <>
        <ActionButton
          variant={'outlined'}
          color={'secondary'}
          onClick={handleClose}
          data-testid={'btn-dialog-cancel'}
        >
          {t('Cancel')}
        </ActionButton>
        <DangerButton
          color='primary'
          onClick={handleDialogAgree}
          data-testid={'btn-dialog-confirm'}
        >
          {t('Confirm')}
        </DangerButton>
      </>
    );

    setTitle(title || null);
    setMessage(message || '');
    setAction(newActions);
    setRest(rest);
    setIsOpen(true);
  };

  const renderContent = () => {
    if (typeof message === 'string') {
      return <DialogContentText>{message}</DialogContentText>;
    }

    return message;
  };

  return (
    <DialogContext.Provider
      value={{
        showDialog,
        hideDialog: handleClose,
      }}
    >
      <Dialog open={isOpen} onClose={handleClose} {...rest}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{renderContent()}</DialogContent>
        {!!action && <DialogActions>{action}</DialogActions>}
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
