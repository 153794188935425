export const cpfMask = '999.999.999-999';
export const cnpjMask = '99.999.999/9999-99';

// TODO - fix mask with space, example: (99) 99999-9999
export const applyMask = (value: string, mask: string) => {
  let result = '';

  let inc = 0;

  Array.from(value).forEach((letter, index) => {
    if (mask[index + inc]) {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }

      result += letter;
    }
  });

  // add next mask item that is not a number
  if (value.length !== 0) {
    const next = value.length + inc;

    if (mask[next]) {
      if (!mask[next].match(/[0-9]/)) {
        result += mask[next];
      }
    }
  }

  return result;
};

export const clear = (value: string) => {
  return value && value.replace(/[^0-9]/g, '');
};

const padArrayStart = (arr: Array<any>, len: number, padding = null) => {
  return Array(len - arr.length)
    .fill(padding)
    .concat(arr);
};

export const applyPaddedMask = (value: string, mask: string) => {
  const clearMask = clear(mask);
  const clearedMask = clear(clearMask);

  const maskArray = Array.from(mask);
  const valueArray = Array.from(value);
  const valuePadded = padArrayStart(valueArray, clearedMask.length);

  let result = '';
  let inc = 0;

  valuePadded.forEach((letter, index) => {
    if (!mask[index + inc].match(/[0-9]/)) {
      result += mask[index + inc];
      inc++;
    }

    if (letter) {
      result += letter;
    } else {
      result += maskArray[index + inc];
    }
  });

  return result;
};
