import { graphql } from 'react-relay';

export const ChargeUpdatedStatus = graphql`
  subscription ChargeUpdatedStatusSubscription(
    $input: ChargeUpdatedStatusInput!
  ) {
    ChargeUpdatedStatus(input: $input) {
      charge {
        id
        status
        payer {
          name
          hasCashbackConfirmed
        }

        ...ChargeActions_charge
        ...ChargeQrCodeDetailInfoCard_charge
        paymentMethods {
          ... on PaymentMethodInstallments {
            method
            subscription {
              installmentsCount
              currentInstallment {
                status
                charge {
                  id
                  status
                }
              }
              installmentsActive: paymentSubscriptionInstallments(
                filters: { status: "ACTIVE" }
              ) {
                edges {
                  node {
                    status
                    installmentNumber
                  }
                }
              }
              installmentsCompleted: paymentSubscriptionInstallments(
                filters: { status: "COMPLETED" }
              ) {
                edges {
                  node {
                    status
                    installmentNumber
                  }
                }
              }
            }
          }
        }
        pixTransactions(first: 1) {
          count
          endCursorOffset
          startCursorOffset
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            cursor
            node {
              id
              customer {
                id
                name
              }
              charge {
                id
                comment
              }
              value
              time
              endToEndId
              infoPagador
            }
          }
        }
      }
    }
  }
`;
