import { graphql, readInlineData } from 'react-relay';

import { ACCOUNT_TYPE_ENUM } from '@woovi/shared';

import type { useAccountActivateWrapper_user$key } from './__generated__/useAccountActivateWrapper_user.graphql';

export const useAccountActivateWrapper = (userRef: useAccountActivateWrapper_user$key) => {
  const user = readInlineData<useAccountActivateWrapper_user$key>(
    graphql`
      fragment useAccountActivateWrapper_user on User @inline {
        company {
          defaultCompanyBankAccount {
            type
          }
        }
      }
    `,
    userRef,
  );

  const isBaasAccount =
    user.company?.defaultCompanyBankAccount?.type !== ACCOUNT_TYPE_ENUM.WALLET;

  return {
    isBaasAccount,
  } as const;
};
