/**
 * @generated SignedSource<<c0de72b41abc08bf73501dbe778de4c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useProviderFeatures_account$data = {
  readonly providerFeatures: ReadonlyArray<string>;
  readonly " $fragmentType": "useProviderFeatures_account";
};
export type useProviderFeatures_account$key = {
  readonly " $data"?: useProviderFeatures_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProviderFeatures_account">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useProviderFeatures_account"
};

(node as any).hash = "7a08e328816b954a6678f40c2cd98136";

export default node;
