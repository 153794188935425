import type { ReactNode } from 'react';
import { createContext } from 'react';

type AppEnvContextType = string;

export const AppEnvContext = createContext<AppEnvContextType>('production');

type Props = {
  children: ReactNode;
  appEnv?: string;
};

export function AppEnvProvider({ children, appEnv = 'production' }: Props) {
  return (
    <AppEnvContext.Provider value={appEnv}>{children}</AppEnvContext.Provider>
  );
}
