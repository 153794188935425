/**
 * @generated SignedSource<<38daac1ea42d3ec4ef4e61a6e8a0b6d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccountItem_account$data = {
  readonly id: string;
  readonly name: string | null | undefined;
  readonly type: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuConfirmDefaultAccountModal_account">;
  readonly " $fragmentType": "UserMenuAccountItem_account";
};
export type UserMenuAccountItem_account$key = {
  readonly " $data"?: UserMenuAccountItem_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItem_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAccountItem_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuConfirmDefaultAccountModal_account"
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "7b413a4407a8a624c83b67c3e5a1f1d0";

export default node;
