/**
 * @generated SignedSource<<702d1a01602c4699b1c8951d8b05a65e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
export type IntegrationType = "MAGENTO1" | "MAGENTO2" | "META_PIXEL" | "NFEIO" | "OPENCART" | "SHOPIFY" | "WABIZ" | "WOOCOMMERCE" | "WOOCOMMERCE_PIX_CREDIT_CARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type usePermission_user$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly integrations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly type: IntegrationType | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
    readonly projectType: string | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "usePermission_user";
};
export type usePermission_user$key = {
  readonly " $data"?: usePermission_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePermission_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "usePermission_user"
};

(node as any).hash = "02c471ec6f7074427e58c895bfc7d6ac";

export default node;
