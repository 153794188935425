import { ChevronRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { formatToCPFOrCNPJ } from 'brazilian-values';
import type { ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';

import { useMutationCallbacks } from '@woovi/relay';
import { ActionButton } from '@woovi/ui';

import type { UserMenuAuthItem_company$key } from './__generated__/UserMenuAuthItem_company.graphql';
import type { UserMenuAuthItem_user$key } from './__generated__/UserMenuAuthItem_user.graphql';
import type { UserMasterCompanyChangeMutation } from './mutations/__generated__/UserMasterCompanyChangeMutation.graphql';
import { UserMasterCompanyChange } from './mutations/UserMasterCompanyChangeMutation';

type UserMenuAuthItemProps = {
  company: UserMenuAuthItem_company$key;
  user: UserMenuAuthItem_user$key;
};

export const UserMenuAuthItem = (props: UserMenuAuthItemProps): ReactNode => {
  const company = useFragment<UserMenuAuthItem_company$key>(
    graphql`
      fragment UserMenuAuthItem_company on Company {
        id
        name
        nameFriendly
        taxID {
          taxID
        }
      }
    `,
    props.company,
  );

  const user = useFragment<UserMenuAuthItem_user$key>(
    graphql`
      fragment UserMenuAuthItem_user on User {
        company {
          id
        }
      }
    `,
    props.user,
  );

  const [userMasterCompanyChange] =
    useMutationCallbacks<UserMasterCompanyChangeMutation>({
      name: 'UserMasterCompanyChange',
      mutation: UserMasterCompanyChange,
      afterCompleted: () => {
        window.location.href = '/home/start';
      },
    });

  if (!company) {
    return null;
  }

  if (user?.company?.id === company.id) {
    return null;
  }

  const handleClick = (): void => {
    const variables = {
      input: {
        companyId: company.id,
      },
    };

    const config = {
      variables,
    };

    userMasterCompanyChange(config);
  };

  return (
    <ActionButton
      variant='text'
      color='secondary'
      onClick={handleClick}
      sx={{ justifyContent: 'space-between' }}
    >
      <Stack alignItems={'start'}>
        {company.nameFriendly || company.name}
        <Typography fontSize={12} color='grey.600'>
          {company?.taxID?.taxID
            ? formatToCPFOrCNPJ(company?.taxID.taxID)
            : '-'}
        </Typography>
      </Stack>
      <ChevronRight />
    </ActionButton>
  );
};
