export enum CARD_PROCESSING_PROVIDERS_ENUM {
  PAGARME = 'PAGARME',
  TEST = 'TEST',
}

export const getCreditCardProviderName = (
  provider: CARD_PROCESSING_PROVIDERS_ENUM,
): string =>
  ({
    [CARD_PROCESSING_PROVIDERS_ENUM.PAGARME]: 'Pagarme',
    [CARD_PROCESSING_PROVIDERS_ENUM.TEST]: 'Test',
  })[provider];
