import * as Sentry from '@sentry/react';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import type { ReactNode } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import { useClarity } from '@woovi/clarity';
import { usePageViewV4 } from '@woovi/hooks';
import type { FetchKeyProp } from '@woovi/relay';
import { withRelayBoundary } from '@woovi/relay';
import { MODULES } from '@woovi/roles';
import { shouldUseSentry } from '@woovi/shared';
import { ContentBoundary, SidebarProvider } from '@woovi/ui';

import type { AdminApp_query$key } from './__generated__/AdminApp_query.graphql';
import type { AdminAppQuery } from './__generated__/AdminAppQuery.graphql';
import { AppEnvProvider } from './AppEnvContext';
import { ChatWoot } from './ChatWoot';
import FooterSpacer from './FooterSpacer';
import GeoLocation from './geoLocation/GeoLocation';
import LayoutWrapper from './LayoutWrapper';
import { CashSound } from './soundCash/CashSound';
import config from '../../config';
import { useSWReg } from '../../firebase/useSWReg';
import { useAuthenticated } from '../../router/middlewares/useAuthenticated';
import { useCompanyRequiredFields } from '../../router/middlewares/useCompanyRequiredFields';
import { useShouldChangePassword } from '../../router/middlewares/useShouldChangePassword';
import { useShouldUserAcceptAgreement } from '../../router/middlewares/useShouldUserAcceptAgreement';
import { useUserRequiredFields } from '../../router/middlewares/useUserRequiredFields';
import type { RouteType } from '../../router/utils';
import { useFilterRoutes } from '../../router/utils/filterRoutes';
import { tokenLogOut } from '../../security/auth';
import { usePermission } from '../../security/usePermission';
import FeatureFlag from '../common/v2/featureFlag/FeatureFlag';
import FeatureTemp from '../common/v2/featureFlag/FeatureTemp';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { useChatwoot } from '../support/useChatwoot';
import { useCrisp } from '../support/useCrisp';

export type AdminAppProps = {
  routes: RouteType;
} & FetchKeyProp;

const AdminApp = ({ routes, fetchKey }: AdminAppProps): ReactNode => {
  const { i18n } = useTranslation();

  const response = useLazyLoadQuery<AdminAppQuery>(
    graphql`
      query AdminAppQuery($first: Int, $status_in: [String!]) {
        ...AdminApp_query
      }
    `,
    {
      first: 1,
      status_in: ['OPENED'],
    },
    {
      fetchKey,
      fetchPolicy: 'network-only',
    },
  );

  const query = useFragment<AdminApp_query$key>(
    graphql`
      fragment AdminApp_query on Query {
        me {
          ...useAuthenticated_user @relay(mask: false)
          ...useCrisp_user
          ...useChatwoot_user
          ...permissionsUserFragment @relay(mask: false)
          ...FeatureTemp_user
          ...CashSound_user
          ...usePermission_user
          ...FeatureFlag_user
          _id
          id
          name
          lang
          company {
            _id
            name
            lang
            timezone
            preferences {
              core {
                agreementTerm {
                  active
                }
              }
            }
            ...useCompanyRequiredFields_company
          }
          hasAcceptAgreementTerm
        }
        meMaster {
          ...useShouldChangePassword_userMaster
          ...useUserRequiredFields_userMaster
        }
        ...Header_query @arguments(first: $first, status_in: $status_in)
        ...Sidebar_query
        appEnv
      }
    `,
    response,
  );

  const { me, meMaster } = query;
  const { hasFeature } = usePermission(me);

  // manages service worker registration and push
  useSWReg();
  usePageViewV4();

  const routesMemo = useFilterRoutes(me, routes);

  const isAuthenticated = useAuthenticated(me);
  const shouldChangePassword = useShouldChangePassword(meMaster);
  const shouldUserAcceptAgreement = useShouldUserAcceptAgreement(me);
  const userRequiredFields = useUserRequiredFields(meMaster);
  const companyRequiredFields = useCompanyRequiredFields(me?.company);

  useEffect(() => {
    if (!me) {
      tokenLogOut();

      return;
    }

    if (shouldUseSentry) {
      if (Sentry.getCurrentScope()) {
        const scope = Sentry.getCurrentScope();

        scope.setUser({
          id: me?._id,
          name: me?.name,
          companyId: me?.company?._id,
          companyName: me?.company?.name,
        });
        scope.setTag('companyId', me.company?._id);
        scope.setTag('companyName', me.company?.name);
      }
    }

    if (process.env.GA_ID) {
      ReactGA.set({ userId: me._id });
    }

    const lang = me?.lang || me.company?.lang || 'pt-BR';

    const changeLanguage = async (): Promise<void> => {
      await i18n.changeLanguage(lang);
    };

    changeLanguage();
  }, [me]);

  useCrisp({
    userRef: me,
  });

  useChatwoot({
    userRef: me,
  });

  useClarity({
    clarityId: config.CLARITY_ID,
    userId: me?._id,
    tags: {
      companyId: me?.company?._id,
    },
  });

  useEffect(() => {
    if (hasFeature([MODULES.DEFAULT_TIMEZONE])) {
      if (!me?.company?.timezone) {
        return;
      }

      moment.tz.setDefault(me?.company?.timezone);
    }
  }, []);

  if (!isAuthenticated) {
    return null;
  }

  if (shouldChangePassword) {
    return null;
  }

  if (shouldUserAcceptAgreement) {
    return null;
  }

  if (userRequiredFields) {
    return null;
  }

  if (companyRequiredFields) {
    return null;
  }

  if (!me) {
    return null;
  }

  return (
    <AppEnvProvider appEnv={query.appEnv}>
      <SidebarProvider>
        <LayoutWrapper
          header={<Header query={query} />}
          sidebar={<Sidebar query={query} />}
        >
          <ContentBoundary>{routesMemo}</ContentBoundary>
          <FooterSpacer />
        </LayoutWrapper>
        <CashSound user={me} />
        <FeatureFlag user={me} features={[MODULES.CHATWOOT]}>
          <ChatWoot />
        </FeatureFlag>
        <FeatureTemp user={me} tempBorder={false}>
          <GeoLocation />
        </FeatureTemp>
      </SidebarProvider>
    </AppEnvProvider>
  );
};

export default withRelayBoundary(AdminApp);
