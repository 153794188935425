export const convertToCurrency = (value: number): string | null => {
  if (typeof value !== 'number') {
    return null;
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};
