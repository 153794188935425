import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/applications';

export const webhookRoutes: RouteType[] = [
  {
    name: 'webhook.create',
    path: `${PATH}/webhook/create`,
    component: loadable(
      () => import('../../../components/webhook/OpenPixWebhookAdd'),
    ),
    exact: true,
    label: ({ t }) => t('Create webhook'),
    labelHeader: ({ t }) => t('Create webhook'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'webhook.details',
    path: `${PATH}/webhook/detail/:id`,
    labelHeader: ({ t }) => t('Webhook Detail'),
    label: ({ t }) => t('Webhook Detail'),
    component: loadable(
      () => import('../../../components/webhook/details/WebhookDetails'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'webhook.details.data',
        path: `${PATH}/webhook/detail/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../../components/webhook/data/WebhookData'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'webhook.details.logs',
        path: `${PATH}/webhook/detail/:id/logs`,
        labelHeader: ({ t }) => t('Logs'),
        label: ({ t }) => t('Logs'),
        component: loadable(
          () => import('../../../components/webhook/logs/WebhookLogs'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
];
