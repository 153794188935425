import type { TFunction } from 'react-i18next';

import type { CHARGE_STATUS_ENUM } from '@woovi/shared';

export const chargeStatusTypeI18n = (t: TFunction, key: string) => {
  const status: Record<keyof typeof CHARGE_STATUS_ENUM, string> = {
    ACTIVE: t('Pending'),
    CANCELED: t('Canceled'),
    COMPLETED: t('Paid'),
    EXPIRED: t('Expired'),
    OVERDUE: t('Overdue'),
    REMOVED_BY_PSP: t('Removed by the Provider'),
    REMOVED_BY_RECEIVER_USER: t('Removed by User'),
    ACTIVE_WAITING_PAYMENT_METHOD: `${t('Pending')}: ${t('Payment')}`,
  };

  return status[key];
};
