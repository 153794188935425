import dot from 'dot-object';
import { useLocation } from 'react-router-dom';

import { stringToValue } from '@woovi/router';
import { queryString } from '@woovi/shared';

export const useQuery = <T extends Record<string, unknown>>(): T => {
  const location = useLocation();

  const query = queryString.parse(location.search?.substring(1));

  const filter = Object.entries(query)
    .map(([key, value]) => ({ [key]: stringToValue(value as string, key) }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  return dot.object(filter) as unknown as T;
};
