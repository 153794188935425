/**
 * @generated SignedSource<<f4207a53fac5190ffaae7fc573c54dcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IntegrationType = "MAGENTO1" | "MAGENTO2" | "META_PIXEL" | "NFEIO" | "OPENCART" | "SHOPIFY" | "WABIZ" | "WOOCOMMERCE" | "WOOCOMMERCE_PIX_CREDIT_CARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Sidebar_query$data = {
  readonly me: {
    readonly allRoles: ReadonlyArray<string | null | undefined>;
    readonly company: {
      readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly integrations: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status: string | null | undefined;
            readonly type: IntegrationType | null | undefined;
          };
        } | null | undefined>;
      } | null | undefined;
      readonly nameFriendly: string | null | undefined;
      readonly projectType: string | null | undefined;
    } | null | undefined;
    readonly hasDataExports: boolean;
    readonly id: string;
    readonly isAdmin: boolean | null | undefined;
    readonly name: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SidebarHeader_me" | "useUserFlagsFragment">;
  } | null | undefined;
  readonly version: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"menuItems_query">;
  readonly " $fragmentType": "Sidebar_query";
};
export type Sidebar_query$key = {
  readonly " $data"?: Sidebar_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sidebar_query">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAdmin",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allRoles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "features",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "IntegrationConnection",
  "kind": "LinkedField",
  "name": "integrations",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IntegrationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Integration",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "active",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "percentage",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sidebar_query",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "menuItems_query",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 3
            }
          ],
          "concreteType": "CompanyBankAccountConnection",
          "kind": "LinkedField",
          "name": "companyBankAccounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyBankAccountEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyBankAccount",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "companyBankAccounts(first:3)"
        },
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "CashbackConnection",
          "kind": "LinkedField",
          "name": "cashbacks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CashbackEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Cashback",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "cashbacks(first:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "me",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "usePermission_user",
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "company",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IntegrationConnection",
                      "kind": "LinkedField",
                      "name": "integrations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "IntegrationEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Integration",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v7/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Company",
              "kind": "LinkedField",
              "name": "company",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v8/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyPreferences",
                  "kind": "LinkedField",
                  "name": "preferences",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OpenPixPreferences",
                      "kind": "LinkedField",
                      "name": "openpix",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CompanyBankAccount",
                          "kind": "LinkedField",
                          "name": "defaultCompanyBankAccount",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompanyPreferencesCashback",
                      "kind": "LinkedField",
                      "name": "cashback",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CompanyPreferencesCashbackExclusive",
                      "kind": "LinkedField",
                      "name": "cashbackExclusive",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v1/*: any*/),
                  "concreteType": "CostRevenueCenterConnection",
                  "kind": "LinkedField",
                  "name": "costRevenueCenters",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CostRevenueCenterEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CostRevenueCenter",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "costRevenueCenters(first:1)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasCompanyBankAccountVirtual",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasCompanyBankAccount",
                  "storageKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "subscriptions_company",
                  "selections": [
                    {
                      "alias": null,
                      "args": (v1/*: any*/),
                      "concreteType": "PaymentSubscriptionConnection",
                      "kind": "LinkedField",
                      "name": "subscriptions",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PaymentSubscriptionEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PaymentSubscription",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "subscriptions(first:1)"
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disputeCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isResponsibleByCostRevenueCenters",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "key",
              "value": "view_sidebar_pix_machine"
            }
          ],
          "kind": "FragmentSpread",
          "name": "useUserFlagsFragment"
        },
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "company",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v8/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nameFriendly",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SidebarHeader_me"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasDataExports",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "d51c42d77f008db0f696b528769a2f4b";

export default node;
