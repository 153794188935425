import type { Location } from 'history';
import { generatePath, matchPath } from 'react-router-dom';

export const hasMatchedLegacy =
  (legacyRoutes: { [key: string]: string }) => (location: Location) => {
    const legacyNames = Object.keys(legacyRoutes);

    const legacyRouteMatched = legacyNames.find((legacy) => {
      const pathInfo = matchPath(location.pathname, {
        path: legacy,
        exact: true,
      });

      return !!pathInfo;
    });

    if (!legacyRouteMatched) {
      return null;
    }

    const legacyRouteInfo = matchPath(location.pathname, {
      path: legacyRouteMatched,
      exact: true,
    });

    const { path, params } = legacyRouteInfo;

    return generatePath(legacyRoutes[path], params);
  };
