export type AccountWithProviderFeatures = {
  providerFeatures?: string[] | readonly string[];
};

export const accountHasProviderFeature = <
  T extends AccountWithProviderFeatures,
>(
  account: T | undefined | null,
  feature: string,
) => {
  return !!account?.providerFeatures?.includes(feature);
};
