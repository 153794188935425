import {
  CARD_PROCESSING_PROVIDERS_ENUM,
  type CardProcessingProvider,
} from '@woovi/card-processing-provider';

import { pagarmeCreditCardTokenize } from './tokenize/pagarmeCreditCardTokenize';

export const getPagarmeProvider = (): CardProcessingProvider => ({
  name: CARD_PROCESSING_PROVIDERS_ENUM.PAGARME,
  creditCardTokenize: pagarmeCreditCardTokenize,
});
