import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import { formatNumber } from 'libphonenumber-js';

import { parsePhoneNumber } from '@woovi/utils';

export const cellTypePhone: GridColTypeDef = {
  renderCell: ({ value }) => {
    if (!value) {
      return '-';
    }

    const parsedPhone = parsePhoneNumber(value);

    if (!parsedPhone) {
      return '-';
    }

    const phone: string = parsedPhone.number;

    return <a href={`tel:${phone}`}>{formatNumber(phone, 'INTERNATIONAL')}</a>;
  },
};
