export enum PROVIDER_FEATURE_ENUM {
  ACCOUNT_ADDITIONAL_INFO = 'ACCOUNT_ADDITIONAL_INFO', // it renders the additional info of an account in it detail settings tab on frontend
  ACCOUNT_REQUIREMENT = 'ACCOUNT_REQUIREMENT', // gives the requirements to open a account
  BAAS = 'BAAS', // is a BaaS
  BAAS_ACCOUNT_PROVIDER = 'BAAS_ACCOUNT_PROVIDER',
  BALANCE = 'BALANCE', // has balance
  COBV_ADDRESS_REQUIRED = 'COBV_ADDRESS_REQUIRED', // if cobv charge requires address from customer
  CREATE_STATIC_PIX_QR_CODE = 'CREATE_STATIC_PIX_QR_CODE', // need to create a static qr code in the provider
  DEFAULT_ACCOUNT = 'DEFAULT_ACCOUNT', // can be defined as default account
  FEE = 'FEE', // has fees
  ITP = 'ITP', // has ITP payment method
  LIMITS = 'LIMITS', // has account limits
  PAY_PIX_KEY_DIRECT = 'PAY_PIX_KEY_DIRECT', // can pay direct to pix key making not necessary consult it at the DICT
  PIX_COB = 'PIX_COB', // has cob payment method
  PIX_COBV = 'PIX_COBV', // has cobv payment method
  PIX_KEY = 'PIX_KEY', // has a Pix key
  PIX_OUT = 'PIX_OUT', // can perform pix out
  PIX_QR_CODE_DISABLE = 'PIX_QR_CODE_DISABLE', // can disable qr codes via the provider
  PIX_KEY_MANAGEMENT = 'PIX_KEY_MANAGEMENT', // can manage pix keys
  REFUND = 'REFUND', // can refund
  STATEMENT = 'STATEMENT', // has statement
  TEST = 'TEST', // is a test provider
  TRANSACTION_POOLING = 'TRANSACTION_POOLING', // can do pooling on all transactions
  WALLET_PROVIDER = 'WALLET_PROVIDER', // can function as the wallet provider
  WITHDRAW = 'WITHDRAW', // can withdraw
  WITHDRAW_OPTION_10AM = 'WITHDRAW_OPTION_10AM', // can withdraw 10am
  WITHDRAW_OPTION_7PM = 'WITHDRAW_OPTION_7PM', // can withdraw 7pm
  WITHDRAW_OPTION_6PM = 'WITHDRAW_OPTION_6PM', // can withdraw 6pm
}
