import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';
import { useHistory } from 'react-router-dom';

import { COMPANY_REQUIRED_FIELDS_ENUM } from '@woovi/shared';

import type { useCompanyRequiredFields_company$key } from './__generated__/useCompanyRequiredFields_company.graphql';
import { routeToMain } from '../utils';

export const useCompanyRequiredFields = (
  companyRef: useCompanyRequiredFields_company$key,
): boolean => {
  const history = useHistory();

  const company = readInlineData<useCompanyRequiredFields_company$key>(
    graphql`
      fragment useCompanyRequiredFields_company on Company @inline {
        requiredFields
      }
    `,
    companyRef,
  );

  const shouldConfirmPlan = company.requiredFields?.includes(
    COMPANY_REQUIRED_FIELDS_ENUM.PLAN,
  );

  useEffect(() => {
    if (shouldConfirmPlan) {
      history.push(routeToMain('required.should-confirm-plan'));
    }
  }, [shouldConfirmPlan]);

  return !!shouldConfirmPlan;
};
