import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import {
  relayTransactionLogger,
  getMissingFieldHandlers,
  requiredFieldLogger,
} from '@woovi/relay';

import { networkFetchObservable } from './observable/networkFetchObservable';
import { subscribeFn } from './subscribeFn';

const isDev = process.env.NODE_ENV === 'development';

const network = Network.create(networkFetchObservable, subscribeFn);

const source = new RecordSource();
const store = new Store(source, {
  // This property tells Relay to not immediately clear its cache when the user
  // navigates around the app. Relay will hold onto the specified number of
  // query results, allowing the user to return to recently visited pages
  // and reusing cached data if its available/fresh.
  gcReleaseBufferSize: 10,
});

const getEnvironmentLog = () => {
  if (isDev) {
    return {
      log: relayTransactionLogger,
    };
  }

  return {};
};

const env = new Environment({
  network,
  store,
  ...getEnvironmentLog(),
  ...getMissingFieldHandlers(),
  relayFieldLogger: requiredFieldLogger,
});

if (isDev) {
  if (typeof window !== 'undefined') {
    window.relayEnvironment = env;
    window.debugRelayStore = () => env.getStore().getSource().toJSON();
  }
}

export default env;
