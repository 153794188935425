/**
 * @generated SignedSource<<8d2d11168c48e62d5054445a95beacce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccounts_user$data = {
  readonly company: {
    readonly companyBankAccounts: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItem_account">;
        } | null | undefined;
      } | null | undefined>;
    };
    readonly defaultCompanyBankAccount: {
      readonly id: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItem_user">;
  readonly " $fragmentType": "UserMenuAccounts_user";
};
export type UserMenuAccounts_user$key = {
  readonly " $data"?: UserMenuAccounts_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccounts_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAccounts_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "concreteType": "CompanyBankAccountConnection",
          "kind": "LinkedField",
          "name": "companyBankAccounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyBankAccountEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CompanyBankAccount",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserMenuAccountItem_account"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CompanyBankAccount",
          "kind": "LinkedField",
          "name": "defaultCompanyBankAccount",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuAccountItem_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "91fae5e2170803b5c898aaff8fe40393";

export default node;
