import { Fade } from '@mui/material';
import { forwardRef } from 'react';

import BoxFlex from '../mui/BoxFlex';

type WindowPopperTransitionProps = {
  in: boolean;
  children: JSX.Element;
};

export const WindowPopperTransition = forwardRef<
  HTMLDivElement,
  WindowPopperTransitionProps
>(({ children, ...props }, ref) => {
  return (
    <BoxFlex tabIndex={-1} ref={ref} sx={{ height: '100%', width: '100%' }}>
      <Fade mountOnEnter unmountOnExit {...props}>
        {children}
      </Fade>
    </BoxFlex>
  );
});
