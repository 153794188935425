/**
 * @generated SignedSource<<a9e3cfff693613ca4b8d91067eedde8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CustomerCreateInput = {
  address?: AddressInputType | null | undefined;
  clientMutationId?: string | null | undefined;
  email?: string | null | undefined;
  name: string;
  phone?: string | null | undefined;
  taxID?: string | null | undefined;
};
export type AddressInputType = {
  city?: string | null | undefined;
  complement?: string | null | undefined;
  country?: string | null | undefined;
  fullAddress?: string | null | undefined;
  location?: LocationCostRevenueCenter | null | undefined;
  neighborhood?: string | null | undefined;
  number?: string | null | undefined;
  rawGoogleApiResponse?: string | null | undefined;
  state?: string | null | undefined;
  street?: string | null | undefined;
  zipcode?: string | null | undefined;
};
export type LocationCostRevenueCenter = {
  lat?: number | null | undefined;
  lng?: number | null | undefined;
};
export type CustomerCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CustomerCreateInput;
};
export type CustomerCreateMutation$data = {
  readonly CustomerCreate: {
    readonly customerEdge: {
      readonly node: {
        readonly email: string | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly phone: string | null | undefined;
        readonly taxID: {
          readonly taxID: string | null | undefined;
          readonly type: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly error: string | null | undefined;
  } | null | undefined;
};
export type CustomerCreateMutation = {
  response: CustomerCreateMutation$data;
  variables: CustomerCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerEdge",
  "kind": "LinkedField",
  "name": "customerEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxID",
          "kind": "LinkedField",
          "name": "taxID",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxID",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CustomerCreatePayload",
        "kind": "LinkedField",
        "name": "CustomerCreate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CustomerCreatePayload",
        "kind": "LinkedField",
        "name": "CustomerCreate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "customerEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28b628293025b4659a85ae2088089ebd",
    "id": null,
    "metadata": {},
    "name": "CustomerCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerCreateMutation(\n  $input: CustomerCreateInput!\n) {\n  CustomerCreate(input: $input) {\n    error\n    customerEdge {\n      node {\n        id\n        name\n        email\n        phone\n        taxID {\n          taxID\n          type\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "045e9738aae167a11a80f7fd4619f070";

export default node;
