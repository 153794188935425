import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

export const cellTypeDate: GridColTypeDef = {
  extendType: 'date',
  valueFormatter: ({ value }) =>
    moment(value).isValid() ? moment(value).format('DD-MM-YYYY') : '-',
};

export const GRID_DATE_CUSTOM_COL_DEF: GridColTypeDef = {
  type: 'dateTime',
  valueFormatter: ({ value }) =>
    moment(value).isValid() ? moment(value).format('DD-MM-YYYY') : '-',
};
