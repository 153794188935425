import type { DialogProps } from '@mui/material/Dialog';
import { createContext } from 'react';

export type ShowDialogArgs = {
  onClose?: () => void;
  title: string;
  message?: string | React.ReactNode | (() => string);
  action?: React.ReactNode[];
  onContinue?: () => void;
} & Partial<DialogProps>;

export type DialogContextProps = {
  showDialog: (args: ShowDialogArgs) => void;
  hideDialog: () => void;
};

const DialogContext = createContext({
  showDialog: (args: ShowDialogArgs) => {}, // eslint-disable-line
  hideDialog: () => {},
});

export default DialogContext;
