import {
  AddCircleOutlineOutlined,
  AddShoppingCart,
  Money,
  Person,
  Pix,
} from '@mui/icons-material';
import PaymentIcon from '@mui/icons-material/Payment';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';
import { useHistory } from 'react-router';

import { useSwipeableModal } from '@woovi/hooks';
import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import { ActionButton, Grid, SwipeableDialog, SwipeableModal } from '@woovi/ui';

import type { QuickActionsModal_me$key } from './__generated__/QuickActionsModal_me.graphql';
import type { QuickActionsModal_query$key } from './__generated__/QuickActionsModal_query.graphql';
import { routeToMain } from '../../router/utils';
import { usePermission } from '../../security/usePermission';
import { AccountActivateWrapper } from '../companyBankAccount/AccountActivateWrapper';
import CustomerCreateFormModal from '../customer/create/CustomerCreateFormModal';
import PaymentAuthenticatorTOTPRequiredModal from '../payment/PaymentAuthenticatorTOTPRequiredModal';

type Props = {
  me: QuickActionsModal_me$key;
  query: QuickActionsModal_query$key;
};
export const QuickActionsModal = (props: Props): ReactNode => {
  const { open, handleOpen, handleClose } = useSwipeableModal();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const [isOpen, setOpen] = useState(false);

  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] =
    useState(false);

  const query = useFragment<QuickActionsModal_query$key>(
    graphql`
      fragment QuickActionsModal_query on Query {
        ...CustomerCreateFormModal_query
      }
    `,
    props.query,
  );

  const me = useFragment<QuickActionsModal_me$key>(
    graphql`
      fragment QuickActionsModal_me on User {
        ...usePermission_user
        ...AccountActivateWrapper_user
        userMaster {
          isTOTPActive
        }
      }
    `,
    props.me,
  );

  const { hasPermission, hasFeature } = usePermission(me);

  const hasAccessPixOut = hasFeature([MODULES.PIX_OUT]);

  const handleGoToNewPaymentRoute = (): void => {
    if (!hasAccessPixOut) {
      return history.push(routeToMain('payments.tutorial'));
    }

    if (!me.userMaster?.isTOTPActive) {
      setOpen(true);

      return;
    }

    handleClose();

    return history.push(routeToMain('pay.create'));
  };

  const handleNewPaymentBill = (): void => {
    if (!me.userMaster?.isTOTPActive) {
      setOpen(true);

      return;
    }

    handleClose();

    return history.push(routeToMain('pay.bill.create'));
  };

  const buttons = [
    {
      label: t('New Charge'),
      icon: <Pix />,
      route: routeToMain('charge.create'),
      hasPermission: hasPermission(
        [MODULES.OPEN_PIX],
        [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_CREATE,
        ],
      ),
    },
    {
      label: t('New Subscription'),
      icon: <Money />,
      route: routeToMain('subscription.create'),
      hasPermission: hasPermission(
        [MODULES.OPEN_PIX],
        [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL, ROLES.OPEN_PIX.USER.ALL],
      ),
    },
    {
      label: t('New Pix Crediary'),
      icon: <Pix />,
      route: routeToMain('charge.pixCrediaryCreate'),
      hasPermission: hasPermission(
        [{ $and: [MODULES.OPEN_PIX, MODULES.PIX_CREDIARY] }],
        [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_CREATE,
        ],
      ),
      wrapper: (children) => (
        <AccountActivateWrapper user={me} children={children} />
      ),
    },
    {
      label: t('New Cashback'),
      icon: <AddShoppingCart />,
      route: routeToMain('cashback.create'),
      hasPermission: hasPermission(
        [MODULES.CASHBACK, MODULES.TEMP],
        [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
      ),
      wrapper: (children) => (
        <AccountActivateWrapper user={me} children={children} />
      ),
    },
    {
      label: t('New Woovi Parcelado'),
      icon: <Pix />,
      route: routeToMain('charge.pixCreditCardCreate'),
      hasPermission: hasPermission(
        [{ $and: [MODULES.OPEN_PIX, MODULES.PIX_CREDIT] }],
        [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_CREATE,
        ],
      ),
      wrapper: (children) => (
        <AccountActivateWrapper user={me} children={children} />
      ),
    },
    {
      label: t('New Quick Charge'),
      icon: <Pix />,
      route: routeToMain('charge.quick-create'),
      hasPermission: hasPermission(
        [MODULES.OPEN_PIX],
        [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_CREATE,
        ],
      ),
    },
    {
      label: t('New Payment'),
      icon: <PaymentIcon />,
      onClick: handleGoToNewPaymentRoute,
      hasPermission: hasPermission(
        [MODULES.PIX_OUT],
        [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL, ROLES.OPEN_PIX.PAYMENTS],
      ),
      wrapper: (children) => (
        <AccountActivateWrapper user={me}>
          <SwipeableDialog isOpen={isOpen} onOpenChange={setOpen}>
            <PaymentAuthenticatorTOTPRequiredModal>
              {children}
            </PaymentAuthenticatorTOTPRequiredModal>
          </SwipeableDialog>
        </AccountActivateWrapper>
      ),
    },
    {
      label: t('New Bill Payment'),
      icon: <PaymentIcon />,
      onClick: handleNewPaymentBill,
      hasPermission: hasPermission(
        [MODULES.PAY_BILLS, MODULES.PIX_OUT],
        [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL, ROLES.OPEN_PIX.PAYMENTS],
      ),
      wrapper: (children) => (
        <AccountActivateWrapper user={me}>
          <SwipeableDialog isOpen={isOpen} onOpenChange={setOpen}>
            <PaymentAuthenticatorTOTPRequiredModal>
              {children}
            </PaymentAuthenticatorTOTPRequiredModal>
          </SwipeableDialog>
        </AccountActivateWrapper>
      ),
    },
    {
      label: t('New Customer'),
      icon: <Person />,
      hasPermission: hasPermission(
        [MODULES.OPEN_PIX],
        [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CUSTOMERS,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
        ],
      ),
      onClick: () => {
        handleClose();
        setIsCreateCustomerModalOpen(true);
      },
    },
  ];

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ color: 'white' }}>
        <AddCircleOutlineOutlined />
      </IconButton>

      <SwipeableModal title={t('New')} open={open} onClose={handleClose}>
        <Grid templateColumns={'1fr 1fr'} gap={theme.spacing(2)}>
          {buttons.map((button) => {
            if (button?.hasPermission) {
              if (button.wrapper) {
                return button.wrapper(
                  <ActionButton
                    key={button.label}
                    startIcon={button.icon}
                    label={button.label}
                    onClick={button.onClick ?? handleClose}
                    link={button.route}
                  />,
                );
              }

              return (
                <ActionButton
                  key={button.label}
                  startIcon={button.icon}
                  label={button.label}
                  onClick={button.onClick ?? handleClose}
                  link={button.route}
                />
              );
            }
          })}
        </Grid>
      </SwipeableModal>

      <CustomerCreateFormModal
        query={query}
        isOpen={isCreateCustomerModalOpen}
        handleModal={setIsCreateCustomerModalOpen}
        afterCompleted={() => {
          history.push(routeToMain('crm.user.customer.list'));
        }}
      />
    </>
  );
};
