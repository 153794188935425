import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import { formatToCPFOrCNPJ } from 'brazilian-values';

export const cellTypeTaxID: GridColTypeDef = {
  valueFormatter: ({ value }) => {
    if (!value) {
      return '-';
    }

    return formatToCPFOrCNPJ(value);
  },
};
