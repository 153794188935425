import { useWindowSize } from 'use-window-size-hook';

export const useWindowHelpers = () => {
  const { width } = useWindowSize();
  const isMobile = width < 600;
  const isTablet = width > 600 && width < 900;
  const isDesktop = width >= 900;

  return {
    isMobile,
    isTablet,
    isDesktop,
    width,
  };
};
