// inspired by https://github.com/MarcoPerson/react-clarity
// https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api

function clarity(...args) {
  if (!window.clarity) {
    throw new Error('Clarity is not initialized');
  }

  window.clarity.apply(undefined, args);
}

function init(id: string) {
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function (...args) {
        (c[a].q = c[a].q || []).push(args);
      };

    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', id);
}

function hasStarted() {
  return typeof window.clarity === 'function';
}

function consent() {
  clarity('consent');
}

function identify(
  userId: string,
  sessionId?: string,
  pageId?: string,
  userHint?: string,
) {
  clarity('identify', userId, sessionId, pageId, userHint);
}

function setTag(key: string, value: string | string[]) {
  clarity('set', key, value);
}

export default {
  init,
  hasStarted,
  consent,
  identify,
  setTag,
};
