import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import moment from 'moment';

export const cellTypeDatetime: GridColTypeDef = {
  extendType: 'dateTime',
  valueFormatter: ({ value }) =>
    moment(value).isValid() ? moment(value).format('DD-MM-YYYY HH:mm') : '-',
};

export const GRID_DATETIME_CUSTOM_COL_DEF: GridColTypeDef = {
  type: 'dateTime',
  valueFormatter: ({ value }) =>
    value && moment(value).isValid()
      ? moment(value).format('DD-MM-YYYY HH:mm')
      : '-',
};
