import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { createContext, useContext, useState, useMemo, useCallback } from 'react';
import type { ReactNode } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { ThemeMuiTheme, ThemeMuiDarkTheme } from './theme/Themes';

type ThemeProviderProps = {
  children: React.ReactNode;
}

type ThemeContextValue = {
  toggleTheme: VoidFunction;
  themeMode: 'light' | 'dark';
}

const ThemeContext = createContext<ThemeContextValue | null>(null);

export const useThemeMode = () => {
  const theme = useContext(ThemeContext);

  return theme as ThemeContextValue;
}

const ThemeProvider = ({ children }: ThemeProviderProps): ReactNode => {
  const [themeState, setThemeState] = useState<'light' | 'dark'>(
    () => localStorage.getItem('themeMode') || 'light',
  );

  const handleToggleTheme = useCallback(() => {
    const newTheme = themeState === 'light' ? 'dark' : 'light';

    localStorage.setItem('themeMode', newTheme);
    setThemeState(newTheme);
  }, [themeState]);

  const value = useMemo<ThemeContextValue>(() => ({
    toggleTheme: handleToggleTheme,
    themeMode: themeState,
  }), [themeState, handleToggleTheme]);

  const theme = themeState === 'light' ? ThemeMuiTheme : ThemeMuiDarkTheme;

  return (
    <ThemeContext.Provider value={value}>
      <MUIThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          {children}
        </StyledThemeProvider>
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeProvider;