export const CHARGE_STATUS_ENUM = {
  ACTIVE_WAITING_PAYMENT_METHOD: 'ACTIVE_WAITING_PAYMENT_METHOD',
  ACTIVE: 'ACTIVE', // ATIVA
  COMPLETED: 'COMPLETED', // CONCLUIDA
  REMOVED_BY_RECEIVER_USER: 'REMOVED_BY_RECEIVER_USER', // REMOVIDA_PELO_USUARIO_RECEBEDOR
  REMOVED_BY_PSP: 'REMOVED_BY_PSP', // REMOVIDA_PELO_PSP
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
  OVERDUE: 'OVERDUE',
  PAID_FROM_ANOTHER_TAX_ID: 'PAID_FROM_ANOTHER_TAX_ID',
} as const;
