import { useEffect } from 'react';
import { graphql, readInlineData } from 'react-relay';

import type { useChatwoot_user$key } from './__generated__/useChatwoot_user.graphql';

const userChatwootFragment = graphql`
  fragment useChatwoot_user on User @inline {
    _id
    id
    name
    email
    company {
      nameFriendly
    }
    phoneNumbers
  }
`;

type UseChatwoot = {
  userRef: useChatwoot_user$key;
  crispId: string;
};

export const useChatwoot = ({ userRef }: UseChatwoot) => {
  const user = readInlineData(userChatwootFragment, userRef);

  const getUserPhone = () => {
    if (user.phoneNumbers && user.phoneNumbers.length > 0) {
      return user.phoneNumbers[0];
    }

    return null;
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    const phone = getUserPhone();

    // get identifier hash from backend

    const onReady = () => {
      window.$chatwoot.setUser(user._id, {
        email: user.email,
        name: user.name,
        phone_number: phone,
        company_name: user.company?.nameFriendly,
        // identifier_hash: identifierHash,
      });
    }

    const onError = (error: unknown) => {
      // eslint-disable-next-line
      console.log(error);
    };

    window.addEventListener('chatwoot:ready', onReady);

    window.addEventListener('chatwoot:error', onError);

    return () => {
      window.removeEventListener('chatwoot:ready', onReady);
      window.addEventListener('chatwoot:error', onError);
    }
  }, [user]);
};
