import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { useFiltersFromLocation } from '@woovi/hooks';
import { GlobalSearchField } from '@woovi/ui';

import { routeToMain } from '../../router/utils';

import type { ReactNode } from 'react';

const HeaderSearchField = (): ReactNode => {
  const history = useHistory();
  const theme = useTheme();
  const { filters } = useFiltersFromLocation(['q']);

  const [search, setSearch] = useState(() => (filters.q as string) || '');

  const handleSearchNavigation = (value: string) => {
    history.push(routeToMain('search.list', {}, { q: value.trim() }));
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    const value = data.get('search');

    if (!value) {
      return;
    }

    handleSearchNavigation(value);
  };

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <Box
      component='form'
      sx={{ [theme.breakpoints.down('sm')]: { width: '100%' } }}
      onSubmit={handleSubmitSearch}
    >
      <GlobalSearchField
        value={search}
        onChange={handleOnChange}
        onDebounceApplied={handleSearchNavigation}
      />
    </Box>
  );
};

export default HeaderSearchField;
