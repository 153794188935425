export const maskMoney = (value: number | null) => {
  const format = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  };

  return Number(value).toLocaleString('pt-BR', format);
};

export const maskMoneyWithoutCurrencySymbol = (value: number) => {
  const format: Intl.NumberFormatOptions = {
    currencyDisplay: 'code',
    style: 'currency',
    currency: 'BRL',
  };

  const removeSymbolRegExp = /[a-z]{3}/i;

  return Intl.NumberFormat('pt-BR', format)
    .format(value)
    .replace(removeSymbolRegExp, '')
    .trim();
};
