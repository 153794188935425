import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { useSidebar } from '@woovi/ui';
// import { LayoutRoot } from '@woovi/ui';

type Props = {
  children: ReactNode;
  header: ReactNode;
  sidebar: ReactNode;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.open ? '240px' : '0')} 1fr;
  height: 100vh;
  width: 100%;
`;

const LayoutWrapper = ({ header, sidebar, children }: Props): ReactNode => {
  const { isSidebarOpen } = useSidebar();

  const isTablet = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));

  if (isTablet) {
    return (
      <>
        {header}
        {sidebar}
        {children}
      </>
    );
  }

  return (
    <Grid open={isSidebarOpen}>
      {sidebar}
      <div style={{ minWidth: 0 }}>
        {header}
        {children}
      </div>
    </Grid>
  );
};

export default LayoutWrapper;
