import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useWindowSize } from 'use-window-size-hook';

import { hasMatched } from '@woovi/router';

import Item from './Item';
import type { ItemType } from './Sidebar';
import useSidebar from './useSidebar';
import { getItemsPath } from './utils';
import BoxFlex from '../mui/BoxFlex';
import Tag from '../tag/Tag';

const getIsActive = ({ link, location, items }) => {
  if (link) {
    return link === location.pathname;
  }

  return getItemsPath(items || [])
    .map((path) => hasMatched(location, { path, exact: false }))
    .reduce((acc, cur) => acc || cur, false);
};

const renderItem = (item, index, isMobile: boolean) => (
  <Item
    link={item.link}
    key={index}
    label={item.label}
    isMobile={isMobile}
    isTemp={item.isTemp}
  />
);

type Props = ItemType & {
  isMobile: boolean;
  newChip?: boolean;
  notificationCounter?: number;
};

const Group = (props: Props) => {
  const {
    link,
    label,
    icon,
    items,
    isMobile,
    newChip,
    notificationCounter,
    isTemp,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { sidebarClose } = useSidebar();

  const [isGroupOpen, setGroupIsOpen] = useState<boolean>(false);

  const { width } = useWindowSize();

  const paddingItem = isMobile ? '8px' : '4px';

  const active = getIsActive({
    link,
    location,
    items,
  });

  const toggleGroup = () => setGroupIsOpen(!isGroupOpen);

  const showNotificationCounter = () => {
    if (notificationCounter && notificationCounter > 0) {
      return true;
    }

    return false;
  }

  const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => {
        // TODO: It's a way to ensure that, if the user clicks in the same route on NavBar, it won't let to reset
        // the query param.
        // It's a behavior that was happening mainly on the Home page.
        // Using `window.location` instead of location from useLocation here because the `location.search` lose their update reference
        // when the user navigates between routes.
        const _link =
          link === window.location.pathname
            ? `${link}${window.location.search}`
            : link;

        return <NavLink to={_link} ref={ref} {...itemProps} search />;
      }),
    [link],
  );

  if (link) {
    // use NavLink
    const onLinkClick = () => {
      if (isMobile) {
        sidebarClose();
      }
    };

    const node = (
      <ListItem
        button
        component={renderLink}
        onClick={onLinkClick}
        selected={active}
        sx={{
          paddingTop: paddingItem,
          paddingBottom: paddingItem,
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <BoxFlex
          sx={{
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <ListItemText primary={label} />
          {newChip && <Tag label={t('New')} size='small' color='primary' />}
          {showNotificationCounter() && (
            <Tag
              label={notificationCounter.toString()}
              size='small'
              color='error'
            />
          )}
        </BoxFlex>
      </ListItem>
    );

    if (isTemp) {
      return <div style={{ border: '1px dashed red' }}>{node}</div>;
    }

    return node;
  }

  return (
    <>
      <ListItem
        button
        onClick={toggleGroup}
        selected={active}
        sx={{
          paddingTop: paddingItem,
          paddingBottom: paddingItem,
        }}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <BoxFlex
          sx={{
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <ListItemText primary={label} />
          {newChip && <Tag label={t('New')} size='small' color='primary' />}
          {showNotificationCounter() && (
            <Tag
              label={notificationCounter.toString()}
              size='small'
              color='error'
            />
          )}
        </BoxFlex>
      </ListItem>
      {items && !!items.length && (
        <Collapse in={isGroupOpen}>
          <List component='div' disablePadding>
            {items.map((item, index) => renderItem(item, index, isMobile))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default Group;
