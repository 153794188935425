import { graphql, useFragment } from 'react-relay';

import { useMutationCallbacks } from '@woovi/relay';

import type { useUserFlagsFragment$key } from './__generated__/useUserFlagsFragment.graphql';
import { UserSetFlag } from '../mutations/UserSetFlagMutation';

export type Flag = {
  key: string;
  value: string;
};

type UseUserFlagsOutput = [
  () => Flag[],
  (flag: Flag, filterKey?: string) => void,
  boolean,
];

export const useUserFlags = (
  userQuery: useUserFlagsFragment$key,
): UseUserFlagsOutput => {
  const user = useFragment<useUserFlagsFragment$key>(
    graphql`
      fragment useUserFlagsFragment on User
      @argumentDefinitions(key: { type: String, defaultValue: null }) {
        id
        flags(key: $key) {
          key
          value
        }
      }
    `,
    userQuery,
  );

  const [userSetFlag, isPending] = useMutationCallbacks({
    name: 'UserSetFlag',
    mutation: UserSetFlag,
  });

  const { flags, id } = user;

  const setUserFlags = (flag: Flag, filterKey?: string) => {
    const input = {
      flag,
      id,
    };

    const config = {
      variables: {
        input,
        key: filterKey,
      },
    };

    userSetFlag(config);
  };

  const getFlags = () => {
    return flags;
  };

  return [getFlags, setUserFlags, isPending];
};
