/**
 * @generated SignedSource<<8a9b1336db52a7e1b29e8353fa9cadd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IntegrationType = "MAGENTO1" | "MAGENTO2" | "META_PIXEL" | "NFEIO" | "OPENCART" | "SHOPIFY" | "WABIZ" | "WOOCOMMERCE" | "WOOCOMMERCE_PIX_CREDIT_CARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useAuthenticated_user$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly integrations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly status: string | null | undefined;
          readonly type: IntegrationType | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
    readonly projectType: string | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "useAuthenticated_user";
};
export type useAuthenticated_user$key = {
  readonly " $data"?: useAuthenticated_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAuthenticated_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAuthenticated_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegrationConnection",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IntegrationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Integration",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allRoles",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ce36ff5a163eea4c76367d23dc84c3cb";

export default node;
