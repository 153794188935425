import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { routeToMain } from '../utils';

export const useShouldUserAcceptAgreement = (me) => {
  const history = useHistory();

  const active = me?.company?.preferences?.core?.agreementTerm?.active ?? false;

  let shouldUserAcceptAgreement = false;

  if (active) {
    shouldUserAcceptAgreement = !me?.hasAcceptAgreementTerm ?? false;
  }

  useEffect(() => {
    if (shouldUserAcceptAgreement) {
      history.push(routeToMain('required.agreement'));
    }
  }, [shouldUserAcceptAgreement]);

  return shouldUserAcceptAgreement;
};
