/**
 * @generated SignedSource<<38d137d78894a76afe079f76cea10494>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subscriptions_company$data = {
  readonly subscriptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      } | null | undefined;
    } | null | undefined>;
  };
  readonly " $fragmentType": "subscriptions_company";
};
export type subscriptions_company$key = {
  readonly " $data"?: subscriptions_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"subscriptions_company">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "subscriptions_company"
};

(node as any).hash = "cdf2dd779980b87d5a3dd379ed905e73";

export default node;
