import type { TextFieldProps } from '@mui/material';
import type { ChangeEventHandler, ReactNode } from 'react';
import type { SpaceProps } from 'styled-system';

import MaskNumberInput from './MaskNumberInput';
import { cnpjMask, cpfMask } from '../mask/MaskUtils';

export type TaxIDInputProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
} & TextFieldProps &
  SpaceProps;
const TaxIDInput = (props: TaxIDInputProps): ReactNode => {
  const { ...rest } = props;

  const maxLength = cnpjMask.length;

  const getMask = (value: string) => {
    return value.length > 11 ? cnpjMask : cpfMask;
  };

  return <MaskNumberInput maxLength={maxLength} getMask={getMask} {...rest} />;
};

export default TaxIDInput;
