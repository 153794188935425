import { graphql } from 'react-relay';

export const CompanyBankAccountSetDefault = graphql`
  mutation CompanyBankAccountSetDefaultMutation(
    $input: CompanyBankAccountSetDefaultInput!
  ) {
    CompanyBankAccountSetDefault(input: $input) {
      success
      error
      companyBankAccount {
        id
        code
        isDefault
        hasBalance
        balance
      }
      company {
        defaultCompanyBankAccount {
          code
        }
      }
    }
  }
`;
