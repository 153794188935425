export const ACCOUNT_REGISTER_STATUS = {
  PENDING: 'PENDING', // user needs to validate all company and partners data
  IN_REVIEW: 'IN_REVIEW', // account register is ready to be reviewed by woovi team
  CREATING: 'CREATING', // creating account
  APPROVED: 'APPROVED', // approved account
  REJECTED: 'REJECTED', // rejected account
  REJECTED_KYC: 'REJECTED_KYC', // rejected by our own KYC
  REJECTED_COMMERCIAL_INTEREST: 'REJECTED_COMMERCIAL_INTEREST', // rejected without commercial interest
  REJECTED_RISK_PROFILE: 'REJECTED_RISK_PROFILE', // rejected due to a risky profile
  REJECTED_IRS_PENDING: 'REJECTED_IRS_PENDING', // rejected due to problems with irs (receita federal)
  FAILED: 'FAILED', // failed creating at provider
};

export const ACCOUNT_REGISTER_PIX_VALIDATED_STATUS = {
  NOT_SENT: 'NOT_SENT',
  SENT: 'SENT',
  VALIDATED: 'VALIDATED',
};
