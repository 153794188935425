export const APP_EVENT_DOMAINS = {
  ACCOUNT_REGISTER: 'ACCOUNT_REGISTER',
  CHARGE: 'CHARGE',
  CHARGE_EDIT: 'CHARGE_EDIT',
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP',
  TRANSACTION: 'TRANSACTION',
  DATA_EXPORT: 'DATA_EXPORT',
  DISPUTE: 'DISPUTE',
};
