import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import {
  Address,
  PhoneInputFormik,
  TaxIDInputFormik,
  TextField,
} from '@woovi/form';
import { BoxFlex, SwipeableModal, Collapse } from '@woovi/ui';

import type { ReactNode } from 'react';

export type CustomerFormModalFieldsProps = {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  renderActions: () => JSX.Element;
  addressIsRequired?: boolean;
};

const CustomerFormModalFields = (props: CustomerFormModalFieldsProps): ReactNode => {
  const { t } = useTranslation();

  const { title, isOpen, handleClose, renderActions, addressIsRequired } =
    props;

  return (
    <SwipeableModal
      title={title}
      open={isOpen}
      onClose={handleClose}
      actions={renderActions()}
    >
      <BoxFlex sx={{ flexDirection: 'column' }}>
        <BoxFlex sx={{ flexDirection: 'column' }}>
          <TextField label={t('Name')} name='name' placeholder={t('Name')} />
          <TaxIDInputFormik
            name='taxID'
            label={t('CPF or CNPJ')}
            placeholder={t('CPF or CNPJ')}
            mt='16px'
          />
          <TextField label={t('Email')} name='email' placeholder={t('Email')} />
          <PhoneInputFormik
            label={t('Phone')}
            name='phone'
            placeholder={t('Phone')}
          />
        </BoxFlex>
        <Collapse
          header={
            <Typography sx={{ display: 'flex', gap: 0.5, fontWeight: 600 }}>
              {t('Address')}
              {!addressIsRequired && (
                <Typography sx={{ opacity: 0.5 }}>{`(${t(
                  'Optional',
                )})`}</Typography>
              )}
            </Typography>
          }
          initialExpanded={addressIsRequired}
        >
          <Address prefix='address' showHeader={false} />
        </Collapse>
      </BoxFlex>
    </SwipeableModal>
  );
};

export default CustomerFormModalFields;
