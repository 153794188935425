import { COMPANY_PROJECT_TYPES_ENUM } from '@woovi/roles';

type ProviderCode = {
  [key: string]: string;
};

export const PROVIDER_CODE: ProviderCode = {
  ITAU: '341',
  GERENCIANET: '364',
  DUMMY: '555',
  TEST: '555',
  OPENPIX_LEDGER: 'XXXX',
  BRADESCO: '237',
  BB: '001',
  BS2: '218',
  MATERA: '23114447',
  SICOOB: '576',
  ORIGINAL: '212',
  SANTANDER: '033',
  DOCK: '08744817',
  WALLET: '8888',
  DELBANK: '435',
  FASTCASH: '45756448',
  FIDUCIA: '382',
};

export const DISPUTE_PROVIDERS = {
  FIDUCIA: PROVIDER_CODE.FIDUCIA,
  MATERA: PROVIDER_CODE.MATERA,
  BS2: PROVIDER_CODE.BS2,
  WOOVI: COMPANY_PROJECT_TYPES_ENUM.WOOVI,
};

export const getProviderName = (providerCode: string): string =>
  ({
    [PROVIDER_CODE.ITAU]: 'Itau',
    [PROVIDER_CODE.GERENCIANET]: 'GerenciaNet',
    [PROVIDER_CODE.TEST]: 'Test Account',
    [PROVIDER_CODE.OPENPIX_LEDGER]: 'OpenPix Ledger',
    [PROVIDER_CODE.BRADESCO]: 'Bradesco',
    [PROVIDER_CODE.BB]: 'Banco do Brasil',
    [PROVIDER_CODE.BS2]: 'BS2',
    [PROVIDER_CODE.MATERA]: 'Matera',
    [PROVIDER_CODE.SICOOB]: 'Sicoob',
    [PROVIDER_CODE.ORIGINAL]: 'Original',
    [PROVIDER_CODE.SANTANDER]: 'Santander',
    [PROVIDER_CODE.DOCK]: 'Dock',
    [PROVIDER_CODE.WALLET]: 'Wallet',
    [PROVIDER_CODE.DELBANK]: 'Delbank',
    [PROVIDER_CODE.FASTCASH]: 'Fastcash',
    [PROVIDER_CODE.FIDUCIA]: 'Fidúcia',
  })[providerCode];
