import Typography from '@mui/material/Typography';
import type { AlignItemsProps } from 'styled-system';

import BoxFlex from '../mui/BoxFlex';

import type { ReactNode } from 'react';

type CardFieldProps = {
  label: string;
  value: React.ReactNode;
  alignField?: AlignItemsProps['alignItems'];
};
const CardField = (
  {
    label,
    value,
    alignField = 'inherit',
  }: CardFieldProps,
): ReactNode => {
  return (
    <BoxFlex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: alignField,
      }}
    >
      <Typography variant='body2' color='GrayText'>
        {label}
      </Typography>
      <Typography
        variant='body1'
        fontWeight={600}
        sx={{
          lineHeight: '16px',
          wordBreak: 'break-word',
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {value}
      </Typography>
    </BoxFlex>
  );
};

export default CardField;
