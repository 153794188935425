import React from 'react';

import { useSwipeableDialogContext } from './SwipeableDialog';
import { composeEventHandlers } from '../utils/composeEventHandlers';

type SwipeableDialogTriggerProps = {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const SwipeableDialogTrigger = (props: SwipeableDialogTriggerProps) => {
  const { setOpen } = useSwipeableDialogContext();
  const { children, ...triggerProps } = props;

  const handleToggleOpen = () => {
    setOpen(true);
  };

  return React.cloneElement(children as React.ReactElement, {
    ...triggerProps,
    onClick: composeEventHandlers(triggerProps.onClick, handleToggleOpen),
  });
};

SwipeableDialogTrigger.displayName = 'SwipeableDialogTrigger';
