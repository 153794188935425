/**
 * @generated SignedSource<<bbdd99eb6f488e30b0f9a90a6fe9dc6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type IntegrationType = "MAGENTO1" | "MAGENTO2" | "META_PIXEL" | "NFEIO" | "OPENCART" | "SHOPIFY" | "WABIZ" | "WOOCOMMERCE" | "WOOCOMMERCE_PIX_CREDIT_CARD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type permissionsUserFragment$data = {
  readonly allRoles: ReadonlyArray<string | null | undefined>;
  readonly company: {
    readonly features: ReadonlyArray<string | null | undefined> | null | undefined;
    readonly integrations: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly status: string | null | undefined;
          readonly type: IntegrationType | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
    readonly projectType: string | null | undefined;
  } | null | undefined;
  readonly isAdmin: boolean | null | undefined;
  readonly " $fragmentType": "permissionsUserFragment";
};
export type permissionsUserFragment$key = {
  readonly " $data"?: permissionsUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"permissionsUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "permissionsUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allRoles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "features",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IntegrationConnection",
          "kind": "LinkedField",
          "name": "integrations",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IntegrationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Integration",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "009363edde2fc6cdcef841c32036f841";

export default node;
