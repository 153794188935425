/**
 * @generated SignedSource<<b72c416a35f2621f4362c05892a45f5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAuthItem_company$data = {
  readonly id: string;
  readonly name: string | null | undefined;
  readonly nameFriendly: string | null | undefined;
  readonly taxID: {
    readonly taxID: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "UserMenuAuthItem_company";
};
export type UserMenuAuthItem_company$key = {
  readonly " $data"?: UserMenuAuthItem_company$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAuthItem_company">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAuthItem_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameFriendly",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxID",
      "kind": "LinkedField",
      "name": "taxID",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxID",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "c8d756c78b2c4b6cafa35e74575a876c";

export default node;
