import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import { APP_PATH } from './appPath';
import type { RouteType } from '../../utils';

const CHARGE_VIEW_ROLES = [
  ROLES.OPEN_PIX.CHARGE_VIEW,
  ROLES.OPEN_PIX.CHARGE_CREATE,
  ROLES.OPEN_PIX.CHARGE_EXPORT,
  ROLES.OPEN_PIX.CHARGE_REPORT,
  ROLES.OPEN_PIX.CHARGE_DELETE,
  ROLES.OPEN_PIX.CHARGE_SEE_ALL,
];

export const chargeRoutes: RouteType[] = [
  {
    name: 'charge',
    path: `${APP_PATH}/charges`,
    component: loadable(
      () => import('../../../components/charge/tab/ChargesTab'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('OpenPix'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.REPORT,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ...CHARGE_VIEW_ROLES,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'charge.report',
        path: `${APP_PATH}/charges/report`,
        labelHeader: ({ t }) => t('Report'),
        label: ({ t }) => t('Report'),
        component: loadable(
          () => import('../../../components/admin/report/OpenPixReport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.REPORT,
          ROLES.OPEN_PIX.CHARGE_REPORT,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.charge.list',
        path: `${APP_PATH}/charges/list`,
        labelHeader: ({ t }) => t('Charges'),
        label: ({ t }) => t('Charges'),
        component: loadable(
          () => import('../../../components/charge/list/ChargeList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.mycharges',
        path: `${APP_PATH}/charges/mycharges`,
        labelHeader: ({ t }) => t('My charges'),
        label: ({ t }) => t('My charges'),
        component: loadable(
          () => import('../../../components/charge/list/OpenPixMyChargeList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_SEE_MINE,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.pixCrediary.tutorial',
        path: `${APP_PATH}/charges/pix-crediary`,
        labelHeader: ({ t }) => t('Pix Crediary'),
        label: ({ t }) => t('Pix Crediary'),
        component: loadable(
          () =>
            import(
              '../../../components/charge/pixCrediary/ChargePixCrediaryTutorial'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.PIX_CREDIARY] }],
      },
      {
        name: 'charge.pixCreditCard.tutorial',
        path: `${APP_PATH}/charges/pix-credit-card`,
        labelHeader: ({ t }) => t('Woovi Parcelado'),
        label: ({ t }) => t('Woovi Parcelado'),
        component: loadable(
          () =>
            import(
              '../../../components/charge/pixCreditCard/ChargePixCreditCardTutorial'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.charge.list.realtime',
        path: `${APP_PATH}/charges/list-realtime`,
        labelHeader: ({ t }) => t('Charges'),
        label: ({ t }) => t('Realtime'),
        component: loadable(
          () => import('../../../components/charge/list/ChargeListRealtime'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'charge.settings',
        path: `${APP_PATH}/charges/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () => import('../../../components/charge/settings/ChargeSettings'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.demo',
        path: `${APP_PATH}/charges/demo`,
        labelHeader: ({ t }) => t('Demo'),
        label: ({ t }) => t('Demo'),
        component: loadable(() => import('../../../components/demo/Demo')),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
        ],
        requiredFeatures: [MODULES.DEMO_EVENT],
      },
      {
        name: 'charge.plugin',
        path: `${APP_PATH}/charges/plugin`,
        labelHeader: ({ t }) => t('Plugin'),
        label: ({ t }) => t('Plugin'),
        component: loadable(
          () => import('../../../components/plugin/OpenPixPluginDemo'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'charge.iframe',
        path: `${APP_PATH}/charges/iframe`,
        labelHeader: ({ t }) => t('IFrame'),
        label: ({ t }) => t('IFrame'),
        component: loadable(
          () => import('../../../components/plugin/IFrameDemo'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'charge.clickpix',
        path: `${APP_PATH}/charges/clickpix`,
        labelHeader: ({ t }) => t('ClickPix'),
        label: ({ t }) => t('ClickPix'),
        component: loadable(
          () => import('../../../components/clickpix/ClickPixDemo'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
    ],
  },
  {
    name: 'charge.import',
    path: `${APP_PATH}/charge-batch`,
    labelHeader: ({ t }) => t('Batch Charge Add'),
    label: ({ t }) => t('Batch Charge Add'),
    component: loadable(
      () => import('../../../components/charge/import/ChargeImport'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'charge.create',
    path: `${APP_PATH}/charge-create`,
    component: loadable(
      () => import('../../../components/charge/create/new/ChargeCreateNew'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'charge.create-new',
    path: `${APP_PATH}/charge-create-new`,
    component: loadable(
      () =>
        import(
          '../../../components/charge/create/new/ChargeCreatePaymentMethods'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.TEMP] }],
  },
  {
    name: 'charge.quick-create',
    path: `${APP_PATH}/charge-quick-create`,
    component: loadable(
      () =>
        import(
          '../../../components/charge/quickCreate/autocomplete/ChargeQuickCreateAutocomplete'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [ADMIN_ROLE],
  },
  {
    name: 'charge.pixCreditCardCreate',
    path: `${APP_PATH}/charge-woovi-parcelado`,
    component: loadable(
      () =>
        import(
          '../../../components/charge/create/pixCreditCard/ChargePixCreditCardCreate'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.PIX_CREDIT] }],
  },
  {
    name: 'charge.creditCardCreate',
    path: `${APP_PATH}/charge-credit-card`,
    component: loadable(
      () =>
        import(
          '../../../components/charge/create/creditCard/ChargeCreditCardCreate'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.CREDIT_CARD] }],
  },
  {
    name: 'charge.pixCrediaryCreate',
    path: `${APP_PATH}/charge-pix-crediary`,
    component: loadable(
      () =>
        import(
          '../../../components/charge/create/pixCrediary/ChargePixCrediaryCreate'
        ),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.PIX_CREDIARY] }],
  },
  {
    name: 'charge.itpCreate',
    path: `${APP_PATH}/charge/itp`,
    component: loadable(
      () => import('../../../components/charge/create/itp/ChargeITPCreate'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.ITP_RECIPIENT] }],
  },
  {
    name: 'charge.boletoCreate',
    path: `${APP_PATH}/charge-boleto`,
    component: loadable(
      () =>
        import('../../../components/charge/create/boleto/ChargeBoletoCreate'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_CREATE,
    ],
    requiredFeatures: [{ $and: [MODULES.OPEN_PIX, MODULES.BOLETO] }],
  },
  {
    name: 'charge.detail',
    path: `${APP_PATH}/charge/:id`,
    component: loadable(
      () => import('../../../components/charge/detail/ChargeDetail'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('Charge'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.SELLER,
      ROLES.OPEN_PIX.CHARGE_SEE_MINE,
      ...CHARGE_VIEW_ROLES,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'charge.data',
        path: `${APP_PATH}/charge/:id/detail`,
        labelHeader: ({ t }) => t('Detail'),
        label: ({ t }) => t('Detail'),
        component: loadable(
          () => import('../../../components/charge/detail/ChargeQrCodeDetail'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_SEE_MINE,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.link',
        path: `${APP_PATH}/charge/:id/link`,
        labelHeader: ({ t }) => t('Link'),
        label: ({ t }) => t('Link'),
        component: loadable(
          () => import('../../../components/charge/link/ChargePaymentLink'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
          ROLES.OPEN_PIX.CHARGE_SEE_MINE,
          ...CHARGE_VIEW_ROLES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'charge.transaction.list',
        path: `${APP_PATH}/charge/:id/transaction`,
        labelHeader: ({ t }) => t('Transactions'),
        label: ({ t }) => t('Transactions'),
        component: loadable(
          () =>
            import('../../../components/charge/detail/ChargeTransactionList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'charge.order',
        path: `${APP_PATH}/charge/:id/order`,
        labelHeader: ({ t }) => t('Plugin'),
        label: ({ t }) => t('Plugin'),
        component: loadable(
          () =>
            import(
              '../../../components/charge/detail/order/ChargeOrderSuccess'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'charge.debug',
        path: `${APP_PATH}/charge/:id/debug`,
        labelHeader: ({ t }) => t('Debug'),
        label: ({ t }) => t('Debug'),
        component: loadable(
          () => import('../../../components/charge/detail/debug/ChargeDebug'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
    ],
  },
];
