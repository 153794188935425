import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import type { MenuRightIcons_query$key } from './__generated__/MenuRightIcons_query.graphql';
import HeaderButtonTheme from './HeaderButtonTheme';
import { Notification } from './notification/Notification';
import { QuickActionsModal } from './QuickActionsModal';
import { ChargeQuickViewWindowPopper } from './quickView/ChargeQuickViewWindowPopper';
import User from './userMenu/User';


import type { ReactNode } from 'react';


const RightWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

type MenuRightIconsProps = {
  query: MenuRightIcons_query$key;
};
export const MenuRightIcons = (props: MenuRightIconsProps): ReactNode => {
  const query = useFragment<MenuRightIcons_query$key>(
    graphql`
      fragment MenuRightIcons_query on Query {
        ...Notification_query
        ...QuickActionsModal_query
        me {
          ...HeaderBanner_me
          ...User_me
          ...QuickActionsModal_me
          ...HeaderButtonTheme_user
        }
      }
    `,
    props.query,
  );

  return (
    <RightWrapper>
      <HeaderButtonTheme user={query.me} />
      <QuickActionsModal me={query.me} query={query} />
      <ChargeQuickViewWindowPopper />
      <Notification query={query} />
      <User me={query.me} />
    </RightWrapper>
  );
};
