import { graphql } from 'react-relay';

export const USER_PUSHENDPOINT_TYPE = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WEB: 'WEB',
};

export const PushTokenAdd = graphql`
  mutation PushTokenAddMutation($input: PushTokenAddInput!) {
    PushTokenAdd(input: $input) {
      error
    }
  }
`;
