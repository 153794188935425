import { getColumnValue } from './CardListVirtualized';
import CardField from '../cardGrid/CardField';
import CardGrid from '../cardGrid/CardGrid';

import type { ReactNode } from 'react';

type CardColumnProps = {
  columns: any[];
  row: any;
  onCardClick?: (card: any, event: React.MouseEvent<HTMLDivElement>) => void;
  handleCardClick?: (
    card: any,
    event: React.MouseEvent<HTMLDivElement>,
  ) => void;
};
export const CardColumn = (props: CardColumnProps): ReactNode => {
  const { columns, row, onCardClick, handleCardClick } = props;

  return (
    <CardGrid
      cardProps={{
        sx: { cursor: onCardClick ? 'pointer' : 'default' },
        onClick: (e) => handleCardClick && handleCardClick(row, e),
      }}
    >
      {columns.map((column, index) => (
        <CardField
          key={index}
          label={column.headerName}
          value={getColumnValue(row, column) ?? '-'}
          alignField={column.alignField}
        />
      ))}
    </CardGrid>
  );
};
