import { useMemo } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { getRoutes } from './routes';
import { drawRoutes } from './utils';

import type { ReactNode } from 'react';

const ClientRouter = (): ReactNode => {
  const routesMemo = useMemo(() => {
    return drawRoutes(getRoutes());
  }, []);

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Switch>{routesMemo}</Switch>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default ClientRouter;
