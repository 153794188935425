export const config = {
  GRAPHQL_URL: process.env.GRAPHQL_URL as string,
  GRAPHQL_SUBSCRIPTION_URL: process.env.GRAPHQL_SUBSCRIPTION_URL as string,
  COMMIT_SHA: process.env.COMMIT_SHA as string,
  CRISP_CHAT_ID: process.env.CRISP_CHAT_ID as string,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID as string,
  OPENPIX_LINK_URL: process.env.OPENPIX_LINK_URL as string,
  OPENPIX_PLUGIN_URL: process.env.OPENPIX_PLUGIN_URL as string,
  OPEN_PIX_APP_ID: process.env.OPEN_PIX_APP_ID as string,
  NODE_ENV: process.env.NODE_ENV as string,

  FIREBASE_VAPID: process.env.FIREBASE_VAPID as string,
  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY as string,
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN as string,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID as string,
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET as string,
  FIREBASE_MESSAGING_SEND_ID: process.env.FIREBASE_MESSAGING_SEND_ID as string,
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID as string,

  APP_ENV: process.env.APP_ENV as string,
  D4SIGN_ENVIRONMENT: process.env.D4SIGN_ENVIRONMENT as string,
  CLARITY_ID: process.env.CLARITY_ID as string,
  GA_ID: process.env.GA_ID as string,
  PAGAR_ME_PUBLIC_KEY: process.env.PAGAR_ME_PUBLIC_KEY as string,
  ENTRYPOINT: process.env.ENTRYPOINT as string,

  WABIZ_GLOBALID: process.env.WABIZ_GLOBALID as string,
  WABIZ_WEBHOOK: process.env.WABIZ_WEBHOOK as string,
  WABIZ_DEFAULT_URL_REDIRECT: process.env.WABIZ_DEFAULT_URL_REDIRECT as string,

  CLICKPIX_PAYMENT_METHOD: process.env.CLICKPIX_PAYMENT_METHOD as string,

  CHATWOOT_BASE_URL: process.env.CHATWOOT_BASE_URL as string,
  CHATWOOT_WEBSITE_TOKEN: process.env.CHATWOOT_WEBSITE_TOKEN as string,
} as const;

export default config;
