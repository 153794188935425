import { graphql } from 'react-relay';

export const CustomerCreate = graphql`
  mutation CustomerCreateMutation(
    $input: CustomerCreateInput!
    $connections: [ID!]!
  ) {
    CustomerCreate(input: $input) {
      error
      customerEdge @prependEdge(connections: $connections) {
        node {
          id
          name
          email
          phone
          taxID {
            taxID
            type
          }
        }
      }
    }
  }
`;
