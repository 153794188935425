import { parseNumber } from '@woovi/form';

import { maskMoney, maskMoneyWithoutCurrencySymbol } from './maskMoney';

export const convertToMoney = (value: string | number) => {
  if (typeof value !== 'number') {
    return null;
  }

  const money = parseNumber(value / 100);

  return maskMoney(money);
};

export const convertToMoneyWithoutMask = (value: number | string) => {
  if (!['number', 'string'].includes(typeof value)) {
    return null;
  }

  const money = parseNumber(value / 100);

  return maskMoneyWithoutCurrencySymbol(money);
};
