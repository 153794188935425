import { ChevronRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useFragment, graphql } from 'react-relay';
import { useHistory, useLocation } from 'react-router-dom';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';
import { PROVIDER_FEATURE_ENUM } from '@woovi/shared';
import { ActionButton, BoxFlex } from '@woovi/ui';

import type { HeaderBanner_me$key } from './__generated__/HeaderBanner_me.graphql';
import { routeToMain } from '../../router/utils';
import { ACCOUNT_REGISTER_STATUS } from '../accountRegister/AccountRegisterUtils';
import FeatureFlag from '../common/v2/featureFlag/FeatureFlag';
import { accountHasProviderFeatures } from '../companyBankAccount/provider/accountHasProviderFeatures';
import { useProviderFeatures } from '../companyBankAccount/provider/useProviderFeatures';

type WarningProps = {
  children: ReactNode;
};

export const Warning = ({ children }: WarningProps): ReactNode => {
  return (
    <BoxFlex
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        px: '12px',
        backgroundColor: 'error.main',
        minHeight: '53px',
      }}
    >
      {children}
    </BoxFlex>
  );
};

type HeaderBannerProps = {
  me: HeaderBanner_me$key;
  disputes: any;
};

const PATHS = [
  '/home/account-register/new',
  '/home/account-register/edit',
  '/home/tutorial',
];

const HeaderBanner = (props: HeaderBannerProps): ReactNode => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const me = useFragment<HeaderBanner_me$key>(
    graphql`
      fragment HeaderBanner_me on User
      @argumentDefinitions(first: { type: "Int", defaultValue: 50 }) {
        ...FeatureFlag_user
        company {
          companyBankAccounts(first: $first)
            @connection(key: "HeaderBanner_companyBankAccounts", filters: []) {
            __id
            edges {
              node {
                providerFeatures
              }
            }
          }
          accountRegisters(first: $first)
            @connection(key: "HeaderBanner_accountRegisters", filters: []) {
            __id
            edges {
              node {
                status
              }
            }
          }
          defaultCompanyBankAccount {
            ...useProviderFeatures_account
          }
          hasDispute
        }
      }
    `,
    props.me,
  );

  const query = useFragment(
    graphql`
      fragment HeaderBanner_query on Query
      @argumentDefinitions(
        status_in: { type: "[String!]" },
        first: { type: "Int" }
      ) {
        disputes(first: $first, filters: { status_in: $status_in }) {
          edges {
            node {
              id
            }
          }
        }
      }
    `,
    props.disputes,
  );

  const { company } = me;

  const { hasProviderFeatures } = useProviderFeatures(
    company?.defaultCompanyBankAccount,
  );

  if (PATHS.some((path) => location.pathname.includes(path))) {
    return null;
  }

  const isDefaultTestAccount = hasProviderFeatures([
    PROVIDER_FEATURE_ENUM.TEST,
  ]);

  const hasNonTestAccount = company?.companyBankAccounts.edges.some(
    (account) =>
      !accountHasProviderFeatures(account?.node, [PROVIDER_FEATURE_ENUM.TEST]),
  );

  const hasNonPendingAccountRegister = () => {
    if (!company?.accountRegisters.edges.length) {
      return false;
    }

    return !company.accountRegisters.edges.some(
      (accountRegister) =>
        accountRegister?.node?.status === ACCOUNT_REGISTER_STATUS.PENDING,
    );
  };

  const hasActiveAccount = hasNonTestAccount || hasNonPendingAccountRegister();

  const getButtonLabel = () => {
    if (isDefaultTestAccount && hasActiveAccount) {
      return t('Change account');
    }

    return t('Activate account');
  };

  const shouldRenderBanner = () => {
    if (!hasActiveAccount) {
      return true;
    }

    if (isDefaultTestAccount && hasActiveAccount) {
      return true;
    }

    return false;
  };

  const shouldRenderDisputeBanner = () => {
    if (query?.disputes?.edges?.length) {
      return true;
    }

    return false;
  };

  const handleClick = () => {
    if (isDefaultTestAccount && hasActiveAccount) {
      return history.push(routeToMain('accounts.pix.list'));
    }

    return history.push(routeToMain('account.register.provider.new'));
  };

  return (
    <>
      {shouldRenderBanner() && (
        <Warning>
          <Typography>
            <b>{t('Test Environment.') + ' '}</b>
            {t('Explore the platform with simulated data')}
          </Typography>
          <FeatureFlag user={me} roles={[ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL]}>
            <ActionButton
              variant='contained'
              color='tertiary'
              endIcon={<ChevronRight />}
              onClick={handleClick}
              sx={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}
            >
              {getButtonLabel()}
            </ActionButton>
          </FeatureFlag>
        </Warning>
      )}
      {shouldRenderDisputeBanner() && (
        <Warning>
          <Stack direction={'column'} spacing={1} my={2}>
            <Typography>
              <b>{t('You have open disputes')}</b>
            </Typography>
            <Typography>
              {t(
                'A dispute occurs when the payer disputes the transaction due to suspected scam or fraud. You have 7 days to respond and avoid a refund.',
              )}
            </Typography>
          </Stack>
          <ActionButton
            variant='contained'
            color='tertiary'
            endIcon={<ChevronRight />}
            onClick={() => history.push(routeToMain('dispute.list'))}
            sx={{ whiteSpace: 'nowrap', minWidth: 'fit-content' }}
          >
            {t('View disputes')}
          </ActionButton>
        </Warning>
      )}
    </>
  );
};

export default HeaderBanner;
