/**
 * @generated SignedSource<<19fced04f98268c30c08f68963172fd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAuths_user$data = {
  readonly userMaster: {
    readonly companies: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"UserMenuAuthItem_company">;
        } | null | undefined;
      } | null | undefined>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAuthItem_user">;
  readonly " $fragmentType": "UserMenuAuths_user";
};
export type UserMenuAuths_user$key = {
  readonly " $data"?: UserMenuAuths_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAuths_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAuths_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "userMaster",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "first"
            }
          ],
          "concreteType": "CompanyConnection",
          "kind": "LinkedField",
          "name": "companies",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompanyEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserMenuAuthItem_company"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserMenuAuthItem_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e2c28d9203f00cafda056527865757a3";

export default node;
