export const USER_NOTIFICATIONS = {
  EMAIL_CHARGE_CREATED: 'EMAIL_CHARGE_CREATED',
  EMAIL_CHARGE_COMPLETED: 'EMAIL_CHARGE_COMPLETED',
  EMAIL_CHARGE_COMPLETED_NOT_SAME_CUSTOMER_PAYER:
    'EMAIL_CHARGE_COMPLETED_NOT_SAME_CUSTOMER_PAYER',
  EMAIL_QRCODE_PAID: 'EMAIL_QRCODE_PAID',
  EMAIL_TRANSACTION_DETACHED: 'EMAIL_TRANSACTION_DETACHED',
  EMAIL_TRANSACTION_REFUNDED: 'EMAIL_TRANSACTION_REFUNDED',
  EMAIL_WITHDRAW_COMPLETED: 'EMAIL_WITHDRAW_COMPLETED',
  WEBPUSH_CHARGE_COMPLETED: 'WEBPUSH_CHARGE_COMPLETED',
  WEBPUSH_CHARGE_COMPLETED_NOT_SAME_CUSTOMER_PAYER:
    'WEBPUSH_CHARGE_COMPLETED_NOT_SAME_CUSTOMER_PAYER',
  WEBPUSH_QRCODE_PAID: 'WEBPUSH_QRCODE_PAID',
  WEBPUSH_TRANSACTION_DETACHED: 'WEBPUSH_TRANSACTION_DETACHED',
  WEBPUSH_TRANSACTION_REFUNDED: 'WEBPUSH_TRANSACTION_REFUNDED',
  WEBPUSH_WITHDRAW_COMPLETED: 'WEBPUSH_WITHDRAW_COMPLETED',

  SOUND_CHARGE_COMPLETED: 'SOUND_CHARGE_COMPLETED',
} as const;
