import type { SxProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import type { ReactNode } from 'react';

type SwipeableDialogTitleProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

const DIALOG_TITLE_NAME = 'SwipeableDialogTitle';

export const SwipeableDialogTitle = (
  props: SwipeableDialogTitleProps,
): ReactNode => {
  const { children, sx } = props;

  return (
    <DialogTitle id='dialog-title' sx={sx}>
      {children}
    </DialogTitle>
  );
};

SwipeableDialogTitle.displayName = DIALOG_TITLE_NAME;
