import DialogContent from '@mui/material/DialogContent';
import type { SxProps } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import BoxFlex from '../mui/BoxFlex';
import { composeStyles } from '../utils/composeStyles';

import type { ReactNode } from 'react';

type SwipeableDialogContentProps = {
  children: React.ReactNode;
  dividers?: boolean;
  sx?: SxProps;
};

const DIALOG_CONTENT_NAME = 'SwipeableDialogContent';

export const SwipeableDialogContent = (props: SwipeableDialogContentProps): ReactNode => {
  const theme = useTheme();

  const { children, dividers = true, sx } = props;

  return (
    <BoxFlex
      sx={composeStyles(
        { maxHeight: theme.sizing.maxModalHeight, overflowY: 'auto' },
        sx,
      )}
    >
      <DialogContent dividers={dividers}>{children}</DialogContent>
    </BoxFlex>
  );
};

SwipeableDialogContent.displayName = DIALOG_CONTENT_NAME;
