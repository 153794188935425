import { graphql, useFragment } from 'react-relay';

import { USER_NOTIFICATIONS } from '@woovi/shared';

import type { CashSound_user$key } from './__generated__/CashSound_user.graphql';
import { CashSoundHook } from './CashSoundHook';

import type { ReactNode } from 'react';

export type Props = {
  user: CashSound_user$key;
};
export const CashSound = (props): ReactNode => {
  const query = useFragment<CashSound_user$key>(
    graphql`
      fragment CashSound_user on User {
        notificationsSettings
      }
    `,
    props?.user,
  );

  const cashSoundPermission = USER_NOTIFICATIONS.SOUND_CHARGE_COMPLETED;

  if (!query?.notificationsSettings?.includes(cashSoundPermission)) {
    return;
  }

  return <CashSoundHook />;
};
