import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/applications';

export const apiPluginsRoutes: RouteType[] = [
  {
    name: 'applications',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('API/Plugins'),
    label: ({ t }) => t('API/Plugins'),
    component: loadable(
      () => import('../../../components/applications/ApiPluginsHeader'),
    ),
    exact: false,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.API_WEBHOOK,
    ],
    requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
    routes: [
      {
        name: 'applications.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () => import('../../../components/applications/ApplicationTutorial'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.CORE.APPLICATION,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.CORE, MODULES.OPEN_PIX],
      },
      {
        name: 'applications.list',
        path: `${PATH}/tab/list`,
        labelHeader: ({ t }) => t('API/Plugins'),
        label: ({ t }) => t('API/Plugins'),
        component: loadable(
          () => import('../../../components/applications/ApiSettings'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.API_WEBHOOK,
        ],
        requiredFeatures: [MODULES.ADJUST, MODULES.OPEN_PIX],
      },
    ],
  },
];

export default apiPluginsRoutes;
