/**
 * @generated SignedSource<<09d470adf534e54f72376d6fdfa9d2c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAccountActivateWrapper_user$data = {
  readonly company: {
    readonly defaultCompanyBankAccount: {
      readonly type: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "useAccountActivateWrapper_user";
};
export type useAccountActivateWrapper_user$key = {
  readonly " $data"?: useAccountActivateWrapper_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAccountActivateWrapper_user">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useAccountActivateWrapper_user"
};

(node as any).hash = "b5b29fea34a7dd224705475a5370d94f";

export default node;
