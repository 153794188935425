/**
 * @generated SignedSource<<5ecb1daf2ade911895ca743b1d1c681d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserGeoLocationAddInput = {
  clientMutationId?: string | null | undefined;
  points: ReadonlyArray<UserGeoLocationInput>;
};
export type UserGeoLocationInput = {
  accuracy?: number | null | undefined;
  latitude: number;
  longitude: number;
  source?: string | null | undefined;
  timestamp?: string | null | undefined;
};
export type UserGeoLocationAddMutation$variables = {
  input: UserGeoLocationAddInput;
};
export type UserGeoLocationAddMutation$data = {
  readonly UserGeoLocationAdd: {
    readonly error: string | null | undefined;
  } | null | undefined;
};
export type UserGeoLocationAddMutation = {
  response: UserGeoLocationAddMutation$data;
  variables: UserGeoLocationAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserGeoLocationAddPayload",
    "kind": "LinkedField",
    "name": "UserGeoLocationAdd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserGeoLocationAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserGeoLocationAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ea3fc46fce9b2a9ecb8e3e7aa926cae",
    "id": null,
    "metadata": {},
    "name": "UserGeoLocationAddMutation",
    "operationKind": "mutation",
    "text": "mutation UserGeoLocationAddMutation(\n  $input: UserGeoLocationAddInput!\n) {\n  UserGeoLocationAdd(input: $input) {\n    error\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d839eb7e18a9884a63f3e7ed062241b";

export default node;
