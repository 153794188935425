/**
 * @generated SignedSource<<dd62b086b99d8ecdd2dcf690f33069c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShouldChangePassword_userMaster$data = {
  readonly shouldChangePassword: boolean | null | undefined;
  readonly " $fragmentType": "useShouldChangePassword_userMaster";
};
export type useShouldChangePassword_userMaster$key = {
  readonly " $data"?: useShouldChangePassword_userMaster$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldChangePassword_userMaster">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useShouldChangePassword_userMaster"
};

(node as any).hash = "eab2422981f4c7a9bdf2a584cef5f345";

export default node;
