import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/material/styles';
import React from 'react';
import type { ReactNode } from 'react';

import { useSwipeableDialogContext } from './SwipeableDialog';
import { SwipeableModalTransition } from '../swipeableModal/SwipeableModalTransition';
import { composeStyles } from '../utils/composeStyles';


type SwipeableDialogModalProps = {
  children: React.ReactNode;
  sx?: SxProps;
};

export const SwipeableDialogModal = (
  {
    children,
    sx,
  }: SwipeableDialogModalProps,
): ReactNode => {
  const theme = useTheme();
  const { open, setOpen, closeOnBackdropClick } = useSwipeableDialogContext();

  const handleCloseModal = () => {
    if (!closeOnBackdropClick) {
      return;
    }

    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      sx={{
        '@media (max-width: 600px)': { zIndex: theme.zIndex.modal },
      }}
    >
      <SwipeableModalTransition in={open}>
        <Paper
          sx={composeStyles(
            {
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              boxShadow: theme.shadows[2],
              borderRadius: 1,
              height: 'fit-content',
              '@media (min-width: 600px)': {
                maxWidth: 700,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              },
            },
            sx,
          )}
        >
          {children}
        </Paper>
      </SwipeableModalTransition>
    </Modal>
  );
};
