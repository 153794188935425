import styled from 'styled-components';
import type { SpaceProps, FlexboxProps } from 'styled-system';
import { space, flexbox } from 'styled-system';

export type GridProps = {
  templateColumns?: string;
  mobileColumns?: string;
  gap?: string;
} & SpaceProps &
  FlexboxProps;
const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.templateColumns ? props.templateColumns : '1fr'};
  grid-gap: ${(props) => (props.gap ? props.gap : '0')};
  ${space}
  ${flexbox}
  
  @media (max-width: 780px) {
    grid-template-columns: repeat(
      ${(props) => (props.mobileColumns ? props.mobileColumns : '2')},
      minmax(150px, 1fr)
    );
  }
`;

export default Grid;
