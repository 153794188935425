import type {
  CardProcessingTokenizeArgs,
  CardProcessingTokenizeReturn,
} from '@woovi/card-processing-provider';
import { onlyNumbers } from '@woovi/shared';

import { pagarmeTokenizeCreditCard } from '../../api/tokenize/pagarmeTokenizeCreditCard';

export const pagarmeCreditCardTokenize = async (
  args: CardProcessingTokenizeArgs,
): Promise<CardProcessingTokenizeReturn> => {
  const { cardTokenizePayload } = args;

  const payload = {
    type: 'card',
    card: {
      number: onlyNumbers(cardTokenizePayload.number),
      holder_name: cardTokenizePayload.holderName,
      holder_document: cardTokenizePayload.holderTaxID,
      exp_month: cardTokenizePayload.expMonth,
      exp_year: cardTokenizePayload.expYear,
      cvv: cardTokenizePayload.cvv,
    },
  };

  const response = await pagarmeTokenizeCreditCard({ payload });

  const responseNotOk = typeof response?.ok === 'boolean' && !response.ok;
  const hasError = responseNotOk || response?.errors?.length > 0;

  if (hasError) {
    return {
      success: false,
      error: JSON.stringify(response.errors),
    };
  }

  return {
    success: true,
    token: response.id,
  };
};
