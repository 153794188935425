import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/integration-nfeio';

export const integrationNfeioRoutes: RouteType[] = [
  {
    name: 'integrationNfeio',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('Integration Nfeio'),
    label: ({ t }) => t('Integration Nfeio'),
    component: loadable(
      () => import('../../components/integration/nfeio/IntegrationNfeioHeader'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.INVOICE],
    routes: [
      {
        name: 'integrationNfeio.tab.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import(
              '../../components/integration/nfeio/tutorial/IntegrationNfeioTutorialTab'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.INVOICE],
      },
      {
        name: 'integrationNfeio.tab.settings',
        path: `${PATH}/tab/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () =>
            import(
              '../../components/integration/nfeio/IntegrationNfeioSettings'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.INVOICE],
      },
    ],
  },
  {
    name: 'integrationNfeio.tutorial',
    path: `${PATH}/tutorial`,
    labelHeader: ({ t }) => t('Tutorial'),
    label: ({ t }) => t('Tutorial'),
    component: loadable(
      () =>
        import(
          '../../components/integration/nfeio/tutorial/IntegrationNfeioTutorial'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE],
    requiredFeatures: [MODULES.INVOICE],
  },
];
