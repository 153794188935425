import { Settings } from '@mui/icons-material';
import { Popover, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'react-relay';
import { useHistory } from 'react-router';

import { ActionButton, BoxFlex } from '@woovi/ui';

import { routeToMain } from '../../../router/utils';
import type { NotificationMenu_query$key } from './__generated__/NotificationMenu_query.graphql';
import NotificationTimeline from './NotificationTimeline';

import type { ReactNode } from 'react';

type NotificationMenuProps = {
  query: NotificationMenu_query$key;
  open: boolean;
  onClose: () => void;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
};

export const NotificationMenu = (
  {
    open,
    onClose,
    anchorEl,
    ...props
  }: NotificationMenuProps,
): ReactNode => {
  const { t } = useTranslation();
  const history = useHistory();

  const query = useFragment<NotificationMenu_query$key>(
    graphql`
      fragment NotificationMenu_query on Query {
        ...NotificationTimeline_me
      }
    `,
    props.query,
  );

  const handleSeeAllClick = () => {
    history.push(routeToMain('timeline'));
    onClose();
  };

  const handleSettingsClick = () => {
    history.push(routeToMain('me.account.edit'));
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack sx={{ py: 1, px: 2, width: '350px', maxWidth: '100%', gap: 1 }}>
        <BoxFlex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontSize='16px' fontWeight='bold'>
            {t('Notifications')}
          </Typography>
          <BoxFlex>
            <ActionButton
              variant='text'
              color='secondary'
              onClick={handleSeeAllClick}
            >
              {t('See Timeline')}
            </ActionButton>

            <IconButton onClick={handleSettingsClick}>
              <Settings />
            </IconButton>
          </BoxFlex>
        </BoxFlex>
        <Divider />
        <NotificationTimeline me={query} />
      </Stack>
    </Popover>
  );
};
