import * as Sentry from '@sentry/react';
import type { ComponentType } from 'react';

import { shouldUseSentry } from '@woovi/shared';

export const withErrorBoundary = <T extends Record<string, unknown>>(
  WrappedComponent: ComponentType<T>,
) => {
  if (!shouldUseSentry) {
    return WrappedComponent;
  }

  return Sentry.withErrorBoundary<T>(WrappedComponent, {});
};
