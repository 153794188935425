/**
 * @generated SignedSource<<426e63df43ca9288f5db3afd34c89e1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationMenu_query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationTimeline_me">;
  readonly " $fragmentType": "NotificationMenu_query";
};
export type NotificationMenu_query$key = {
  readonly " $data"?: NotificationMenu_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationMenu_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationMenu_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationTimeline_me"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4fa5cc8622bd9ed4d6344b5949c33049";

export default node;
