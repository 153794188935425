// remove legacy routes after 6 months, check git history
export const legacyRoutes = {
  // example
  // '/home/feedbacksOneToOne': '/home/oneOnOne/overview/myOneOnOne/registered',

  '/home/openpix/start': '/home/start',
  '/home/openpix/companyBank/details/:id': '/home/companyBank/details/:id',
  '/home/openpix/companyBank/details/:id/data':
    '/home/companyBank/details/:id/data',
  '/home/openpix/companyBank/details/:id/transactions':
    '/home/companyBank/details/:id/transactions',
  '/home/openpix/companyBank/details/:id/charges':
    '/home/companyBank/details/:id/charges',
  '/home/openpix/companyBank/details/:id/statement':
    '/home/companyBank/details/:id/statement',
  '/home/openpix/companyBank/details/:id/settings':
    '/home/companyBank/details/:id/settings',
  '/home/openpix/companyBank/details/:id/statementTemp':
    '/home/companyBank/details/:id/statementTemp',
  '/home/openpix/company/bank/account/create':
    '/home/company/bank/account/create',
  '/home/openpix/tutorial': '/home/tutorial',
  '/home/openpix/about': '/home/about',
  '/home/openpix/charges': '/home/charges',
  '/home/openpix/charges/report': '/home/charges/report',
  '/home/openpix/charges/list': '/home/charges/list',
  '/home/openpix/charges/mycharges': '/home/charges/mycharges',
  '/home/openpix/charges/plugin': '/home/charges/plugin',
  '/home/openpix/charges/ecommerce': '/home/charges/ecommerce',
  '/home/openpix/charges/settings': '/home/charges/settings',
  '/home/openpix/charge-batch': '/home/charge-batch',
  '/home/openpix/charge-create': '/home/charge-create',
  '/home/openpix/charge/:id': '/home/charge/:id',
  '/home/openpix/charge/:id/detail': '/home/charge/:id/detail',
  '/home/openpix/charge/:id/transaction': '/home/charge/:id/transaction',
  '/home/openpix/charge/:id/order': '/home/charge/:id/order',
  '/home/openpix/charge/:id/debug': '/home/charge/:id/debug',
  '/home/openpix/payments': '/home/payments',
  '/home/openpix/payments/withdraws': '/home/payments/withdraws',
  '/home/openpix/payments/list': '/home/payments/list',
  '/home/openpix/payment/:id': '/home/payment/:id',
  '/home/openpix/payment/:id/data': '/home/payment/:id/data',
  '/home/openpix/payment-create': '/home/payment-create',
  '/home/openpix/webhook-create': '/home/api/webhook/create',
  '/home/openpix/webhook-detail/:id': '/home/api/webhook/detail/:id',
  '/home/openpix/webhook-detail/:id/data': '/home/api/webhook/detail/:id/data',
  '/home/openpix/webhook-detail/:id/logs': '/home/api/webhook/detail/:id/logs',
  '/home/openpix/transactions': '/home/transactions',
  '/home/openpix/transactions/report': '/home/transactions/report',
  '/home/openpix/transactions/list': '/home/transactions/list',
  '/home/openpix/transactions/refunds': '/home/transactions/refunds',
  '/home/openpix/transactions/disputes': '/home/transactions/disputes',
  '/home/openpix/transaction/details/:id': '/home/transaction/details/:id',
  '/home/openpix/transaction/details/:id/data':
    '/home/transaction/details/:id/data',
  '/home/openpix/new-company': '/home/new-company',
  '/home/openpix/partner': '/home/partner',
  '/home/openpix/partner/company': '/home/partner/company',
  '/home/openpix/partner/preRegistration': '/home/partner/preRegistration',
  '/home/openpix/partner/settings': '/home/partner/settings',
  '/home/openpix/preRegistration/:id/data': '/home/preRegistration/:id/data',
  '/home/openpix/company/details/:id': '/home/company/details/:id',
  '/home/openpix/company/details/:id/data': '/home/company/details/:id/data',
  '/home/openpix/company/details/:id/charges':
    '/home/company/details/:id/charges',
  '/home/openpix/company/details/:id/webhookLogs':
    '/home/company/details/:id/webhookLogs',
  '/home/openpix/dispute/:id': '/home/dispute/:id',
  '/home/openpix/dispute/details/:id/data': '/home/dispute/details/:id/data',

  //application routes
  '/home/settings/application/add': '/home/applications/add',
  '/home/settings/application/detail/:id': '/home/applications/detail/:id',
  '/home/settings/application/detail/:id/data':
    '/home/applications/detail/:id/data',
  '/home/settings/application/detail/:id/logs':
    '/home/applications/detail/:id/logs',
  '/home/settings/tabs/exports': '/home/me/account/exports',

  '/home/api/webhook/create': '/home/applications/webhook/create',
  '/home/api/webhook/webhook/detail/:id':
    '/home/applications/webhook/detail/:id',
  '/home/api/webhook/webhook/detail/:id/data':
    '/home/applications/webhook/detail/:id/data',
  '/home/api/webhook/webhook/detail/:id/logs':
    '/home/applications/webhook/detail/:id/logs',

  '/home/about': '/home/help',

  '/home/permissions/user/:id/roles': '/home/permissions/user/:id/edit',
  '/home/permissions/user/:id/groups': '/home/permissions/user/:id/edit',
};
