import type { ReactNode } from 'react';
import { useState, createContext } from 'react';

import { useWindowHelpers } from '@woovi/hooks';

type SidebarContextType = [boolean, (isOpen: boolean) => void];

export const SidebarContext = createContext<SidebarContextType>([]);

type Props = {
  children: ReactNode;
  isStartOpen?: boolean | null;
};
export function SidebarProvider({ children, isStartOpen = null }: Props) {
  const { isTablet, isDesktop } = useWindowHelpers();

  const getSidebarInitialState = () => {
    if (typeof isStartOpen !== 'boolean') {
      return isTablet || isDesktop;
    }

    return isStartOpen;
  };

  const [isOpen, setIsOpen] = useState<boolean>(getSidebarInitialState());

  return (
    <SidebarContext.Provider value={[isOpen, setIsOpen]}>
      {children}
    </SidebarContext.Provider>
  );
}
