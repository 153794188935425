import dot from 'dot-object';

import getQuery from './getQuery';
import { stringToValue } from './stringToValue';

// TODO: We should remove `any` and `void` type in the future.
type GetFiltersFromLocationFunction = <Key extends string>(
  filterKeys?: Key[],
  notParseKeys?: string[],
) => Record<Key, string> | void | Record<string, any>;

export const getFiltersFromLocation: GetFiltersFromLocationFunction = (
  filterKeys = [],
  notParseKeys = ['search', 'comment', 'bankProviderCode'],
) => {
  const queryParams: any = getQuery();

  const filter = filterKeys
    .map((key) => {
      let value = stringToValue(dot.pick(key, queryParams), key);

      if (typeof value !== 'boolean' && value !== null && !value) {
        return;
      }

      if (
        typeof value !== 'boolean' &&
        Number(value) &&
        notParseKeys.indexOf(key) === -1
      ) {
        value = Number(value);
      }

      if (typeof value === 'object' && value !== null) {
        Object.keys(value).map((key) => {
          if (Number(value[key]) && parseFloat(value[key])) {
            value[key] = parseFloat(value[key]);
          }
        });
      }

      return { [key]: value };
    })
    .reduce((acc, filter) => ({ ...acc, ...filter }), {});

  return filter ? dot.object(filter) : {};
};
