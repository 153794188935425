/**
 * @generated SignedSource<<5da4cd97be01d006e91171265f676715>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuProfile_user$data = {
  readonly profileImage: {
    readonly url: string | null | undefined;
  } | null | undefined;
  readonly userMaster: {
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "UserMenuProfile_user";
};
export type UserMenuProfile_user$key = {
  readonly " $data"?: UserMenuProfile_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuProfile_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuProfile_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "profileImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserMaster",
      "kind": "LinkedField",
      "name": "userMaster",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dccd1b1c5871631d898776374c32fd8b";

export default node;
