import type { TextFieldProps as InternalTextFieldProps } from './TextField';
import TextField from './TextField';
import type { TextFieldFormikProps } from './TextFieldFormik';
import TextFieldFormik from './TextFieldFormik';

export type TextFieldProps = {
  useFormik?: boolean | null;
} & (InternalTextFieldProps | TextFieldFormikProps);

// TODO We should deprecate this
export default ({ useFormik = true, ...props }: TextFieldProps) =>
  useFormik ? <TextFieldFormik {...props} /> : <TextField {...props} />;
