import useScrollTrigger from '@mui/material/useScrollTrigger';
import { cloneElement } from 'react';

export const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};
