import { useEffect } from 'react';
import { useMutation } from 'react-relay';
import { useGeolocation } from 'react-use';

import type { UserGeoLocationAddMutation } from './__generated__/UserGeoLocationAddMutation.graphql';
import { UserGeoLocationAdd } from './UserGeoLocationAddMutation';
import config from '../../../config';

export const useGeolocationUserLocationAdd = () => {
  const [userGeolocationAdd] =
    useMutation<UserGeoLocationAddMutation>(UserGeoLocationAdd);

  const geolocation = useGeolocation();

  const saveGeolocation = (geolocation) => {
    const { latitude, longitude, timestamp } = geolocation;

    const points = [
      {
        latitude,
        longitude,
        source: 'WEB',
        timestamp: new Date(timestamp).toISOString(),
      },
    ];

    const input = {
      points,
    };

    const config = {
      variables: {
        input,
      },
    };

    userGeolocationAdd(config);
  };

  useEffect(() => {
    if (config.APP_ENV === 'development') {
      return;
    }

    if (!geolocation.loading && geolocation.latitude) {
      saveGeolocation(geolocation);
    }
  }, [geolocation.latitude]);
};
