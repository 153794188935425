import AdminApp from '../../../components/root/AdminApp';
import type { RouteType } from '../../utils';
import { cashbackRoutes } from '../cashbackRoutes';
import { clickpixRoutes } from '../clickpixRoutes';
import apiPluginsRoutes from '../core/apiPluginsRoutes';
import permissionsRoutes from '../core/permissionsRoutes';
import settingsRoutes from '../core/settingsRoutes';
import { crmRoutes } from '../crmRoutes';
import homeRoutes from '../homeRoutes';
import { integrationNfeioRoutes } from '../integrationNfeioRoutes';
import { invoiceRoutes } from '../invoiceRoutes';
import accountsRoutes from '../openpix/accountsRoutes';
import { openpixRoutes } from '../openpix/openpixRoutes';
import { partnerRoutes } from '../partnerRoutes';
import { pixMachineRoutes } from '../pixMachineRoutes';
import areasRoutes from '../planning/areasRoutes';
import { rewardsRoutes } from '../rewardsRoutes';
import routeHandlers from '../routeHandlers';
import searchRoutes from '../searchRoutes';
import meRoutes from '../user/meRoutes';

const PATH = '/home';

const rootRoutes: RouteType = {
  name: 'rootRoutes',
  path: PATH,
  exact: false,
  component: AdminApp,
  routes: [
    ...settingsRoutes,
    ...meRoutes,
    ...permissionsRoutes,
    ...homeRoutes,
    ...openpixRoutes,
    ...cashbackRoutes,
    ...crmRoutes,
    ...rewardsRoutes,
    ...apiPluginsRoutes,
    ...accountsRoutes,
    ...areasRoutes,
    ...partnerRoutes,
    ...searchRoutes,
    ...pixMachineRoutes,
    ...integrationNfeioRoutes,
    ...clickpixRoutes,
    ...invoiceRoutes,
    ...routeHandlers(PATH),
  ],
};

export default rootRoutes;
