/**
 * @generated SignedSource<<0120741a41bb8f4c81767afb78a9cc43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UserSetFlagInput = {
  clientMutationId?: string | null | undefined;
  flag: ParamInput;
  id: string;
};
export type ParamInput = {
  key: string;
  value?: string | null | undefined;
  values?: ReadonlyArray<string> | null | undefined;
};
export type UserSetFlagMutation$variables = {
  input: UserSetFlagInput;
  key?: string | null | undefined;
};
export type UserSetFlagMutation$data = {
  readonly UserSetFlag: {
    readonly error: string | null | undefined;
    readonly user: {
      readonly flags: ReadonlyArray<{
        readonly key: string | null | undefined;
        readonly value: string | null | undefined;
      } | null | undefined> | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type UserSetFlagMutation = {
  response: UserSetFlagMutation$data;
  variables: UserSetFlagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "key"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserSetFlagPayload",
    "kind": "LinkedField",
    "name": "UserSetFlag",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "key",
                "variableName": "key"
              }
            ],
            "concreteType": "Parameter",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSetFlagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSetFlagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dfe2b06f02b742d66e0e2b3e0eeb69c3",
    "id": null,
    "metadata": {},
    "name": "UserSetFlagMutation",
    "operationKind": "mutation",
    "text": "mutation UserSetFlagMutation(\n  $input: UserSetFlagInput!\n  $key: String\n) {\n  UserSetFlag(input: $input) {\n    error\n    user {\n      id\n      name\n      flags(key: $key) {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7a2046d5d6db398888cefbc3c407e3f";

export default node;
