import type { CircularProgressProps } from '@mui/material/CircularProgress';
import _CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import styled from 'styled-components';

const CircularProgress = styled(_CircularProgress)`
  stroke-linecap: round;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export type LoadingProps = {
  fullScreen?: boolean;
  wrappedInDialog?: boolean;
  size?: number;
} & CircularProgressProps;

const Loading = ({ fullScreen, wrappedInDialog, size }: LoadingProps) => {
  const progress = <CircularProgress size={size} data-testid='loading' />;

  if (fullScreen) {
    return <Container>{progress}</Container>;
  }

  if (wrappedInDialog) {
    return (
      <Dialog open={true}>
        <DialogContent>{progress}</DialogContent>
      </Dialog>
    );
  }

  return progress;
};

export default Loading;
