import type { RouteType } from '../../../router/utils';
import { drawRoutes } from '../../../router/utils';

type Props = {
  routes: RouteType[];
};

const RequiredTemplate = ({ routes }: Props) => {
  return drawRoutes(routes);
};

export default RequiredTemplate;
