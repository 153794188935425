import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/crm';

export const crmRoutes: RouteType[] = [
  {
    name: 'crm.user',
    path: `${PATH}/tab`,
    component: loadable(
      () => import('../../components/customer/list/OpenPixCustomer'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('CRM'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CUSTOMERS,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.SELLER,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'crm.user.customer.report',
        path: `${PATH}/tab/report`,
        labelHeader: ({ t }) => t('Report'),
        label: ({ t }) => t('Report'),
        component: loadable(
          () => import('../../components/customer/report/CustomerReport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CUSTOMERS,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'crm.user.customer.list',
        path: `${PATH}/tab/list`,
        labelHeader: ({ t }) => t('Customers'),
        label: ({ t }) => t('Customers'),
        component: loadable(
          () => import('../../components/customer/list/OpenPixCustomerList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CUSTOMERS,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'crm.customer.create',
    path: `${PATH}/create`,
    component: loadable(
      () => import('../../components/customer/CustomerCreateForm'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CUSTOMERS,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.SELLER,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
  {
    name: 'customer',
    path: `${PATH}/customer/:customerId`,
    component: loadable(
      () => import('../../components/customer/detail/CustomerDetail'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('OpenPix'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CUSTOMERS,
      ROLES.OPEN_PIX.CHARGES,
      ROLES.OPEN_PIX.USER.ALL,
      ROLES.OPEN_PIX.SELLER,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'customer.detail',
        path: `${PATH}/customer/:customerId/detail`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () => import('../../components/customer/detail/CustomerData'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.CUSTOMERS,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.CHARGES,
          ROLES.OPEN_PIX.SELLER,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'customer.create.batch',
    path: `${PATH}/create-batch`,
    component: loadable(
      () => import('../../components/customer/import/CustomerImport'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.CUSTOMERS,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];
