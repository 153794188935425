import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';
import type { FlexboxProps, SpaceProps } from 'styled-system';
import { flexbox, space } from 'styled-system';

import type { ReactNode } from 'react';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${flexbox}
  ${space}
`;

// Remove tags from composable names
function removerNameParts(nome: string) {
  const particulas = [
    ' da ',
    ' do ',
    ' dos ',
    ' das ',
    ' e ',
    ' di ',
    ' du ',
    ' del ',
    ' della ',
  ];

  particulas.forEach((particula) => {
    nome = nome.replace(particula, ' ').trim();
  });

  return nome;
}

export const AvatarCircle = styled(Avatar)`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  font-size: ${(props) => props.size / 2}px;
  background-color: ${(props) => props.theme.button.backgroundColor};
  color: ${(props) => props.theme.button.textColor};
  border: solid 1px ${(props) => props.theme.palette.divider};
`;

export type Props = {
  name: string;
  size?: number;
  imageUrl?: string;
  square: boolean;
  alt?: string;
} & FlexboxProps &
  SpaceProps;
const AvatarUI = ({ name, size = 50, imageUrl, alt, ...props }: Props): ReactNode => {
  const initials = name
    ? removerNameParts(name)
        .split(' ')
        .slice(0, 2)
        .map((namePart) => namePart.charAt(0))
        .join('')
    : 'AN';

  return (
    <Container {...props}>
      {imageUrl ? (
        <AvatarCircle size={size} src={imageUrl} alt={alt} />
      ) : (
        <AvatarCircle size={size}>{initials}</AvatarCircle>
      )}
    </Container>
  );
};

export default AvatarUI;
