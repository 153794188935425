// https://stackoverflow.com/a/9851769
export const browserDetectors = {
  isIE: () => false || !!document.documentMode,
};

export const isUnSupportedBrowser = (): boolean => {
  // enable any browser in staging
  if (process.env.APP_ENV === 'staging') {
    return false;
  }

  const unsupportedBrowsers = [browserDetectors.isIE];

  return !!unsupportedBrowsers.find((checker) => checker());
};
