export const getSelected = () => {
  if (window.getSelection) {
    return window?.getSelection()?.toString();
  }

  if (document.getSelection) {
    return document?.getSelection()?.toString();
  }

  const selection = document.selection && document.selection.createRange();

  if (selection.text) {
    return selection.text.toString();
  }

  return '';
};
