/**
 * @generated SignedSource<<390af3862a919a45bf4f0e6165f63cf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccountItemBalance_account$data = {
  readonly balance: number | null | undefined;
  readonly code: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useProviderFeatures_account">;
  readonly " $fragmentType": "UserMenuAccountItemBalance_account";
};
export type UserMenuAccountItemBalance_account$key = {
  readonly " $data"?: UserMenuAccountItemBalance_account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItemBalance_account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMenuAccountItemBalance_account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useProviderFeatures_account",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "providerFeatures",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "CompanyBankAccount",
  "abstractKey": null
};

(node as any).hash = "c903cc1e19061846ddfd21490d47c2d5";

export default node;
