import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';

import { routeToMain } from '../../../../router/utils';

export const charges = ({ t, hasRole }) => {
  const canSeeReport = hasRole([
    ADMIN_ROLE,
    ROLES.OPEN_PIX.ADMIN.ALL,
    ROLES.OPEN_PIX.CHARGES,
    ROLES.OPEN_PIX.REPORT,
  ]);

  const isUser =
    hasRole([ROLES.OPEN_PIX.USER.ALL]) ||
    hasRole([ROLES.OPEN_PIX.CHARGES]) ||
    hasRole([ROLES.OPEN_PIX.CHARGE_SEE_ALL]);

  const canSeeOwnCharges = hasRole([
    ROLES.OPEN_PIX.SELLER,
    ROLES.OPEN_PIX.CHARGE_SEE_MINE,
  ]);

  const chargeRoute = {
    label: t('Charges'),
    icon: <AttachMoneyIcon />,
  };

  if (canSeeReport) {
    return [
      {
        ...chargeRoute,
        link: routeToMain('charge.report'),
      },
    ];
  }

  if (canSeeOwnCharges) {
    return [
      {
        ...chargeRoute,
        link: routeToMain('charge.mycharges'),
      },
    ];
  }

  if (isUser) {
    return [
      {
        ...chargeRoute,
        link: routeToMain('charge.charge.list'),
      },
    ];
  }

  return [
    {
      ...chargeRoute,
      icon: <AttachMoneyIcon />,
      link: routeToMain('charge'),
    },
  ];
};
