import { hsl, parseToHsl, parseToRgb, rgb } from 'polished';

type HexColor = string;

// https://css-tricks.com/snippets/javascript/lighten-darken-color/
export const getShadeOfColor = (hex: HexColor, amount: number) => {
  let usePound = false;

  if (hex[0] == '#') {
    hex = hex.slice(1);
    usePound = true;
  }

  const num = parseInt(hex, 16);
  let r = (num >> 16) + amount;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amount;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

// https://stackoverflow.com/a/39077686
export const hexToRgb = (hex: HexColor): number[] =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b,
    )
    .substring(1)
    .match(/.{2}/g)!
    .map((x) => parseInt(x, 16));

export const hexToRgbaString = (
  hex: HexColor,
  opacity: number | string,
): string => {
  const rgbValues = hexToRgb(hex).join(',');

  if (opacity) {
    return `rgba(${rgbValues}, ${opacity})`;
  }

  return `rgba(${rgbValues})`;
};

type ColorSchemeObject = {
  [name: string]: HexColor;
};

type SchemeGenerator<T> = (pivotColor: HexColor) => T;

const cycleValue = (value: number, min: number, max: number): number => {
  if (value < min) {
    return max - Math.abs(value);
  }

  if (value > max) {
    return min + (value - max);
  }

  return value;
};

const minmaxValue = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const buildSchemeGenerator = <T = ColorSchemeObject>(
  ref: HexColor,
  scheme: ColorSchemeObject,
): SchemeGenerator<T> => {
  const refHSL = parseToHsl(ref);

  const schemeTransformers = Object.entries(scheme).map(
    ([key, schemeColor]) => {
      const schemeColorHSL = parseToHsl(schemeColor);

      const transformToScheme = (pivotColor: HexColor) => {
        const pivotColorHSL = parseToHsl(pivotColor);

        const newColorHue =
          pivotColorHSL.hue + (schemeColorHSL.hue - refHSL.hue);

        const newColorLightness =
          pivotColorHSL.lightness +
          (schemeColorHSL.lightness - refHSL.lightness);

        const newColorSaturation =
          pivotColorHSL.saturation +
          (schemeColorHSL.saturation - refHSL.saturation);

        const newSchemeColor = {
          hue: cycleValue(Math.round(newColorHue), 0, 360),
          lightness: minmaxValue(Number(newColorLightness.toFixed(2)), 0, 1),
          saturation: minmaxValue(Number(newColorSaturation.toFixed(2)), 0, 1),
        };

        return rgb(parseToRgb(hsl(newSchemeColor)));
      };

      return [key, transformToScheme] as [
        string,
        (pivotColor: HexColor) => any,
      ];
    },
  );

  return (pivotColor: HexColor) => {
    return schemeTransformers
      .map(([key, transformBy]) => [key, transformBy(pivotColor)])
      .reduce((acc, entry) => ({ ...acc, [entry[0]]: entry[1] }), {}) as T;
  };
};
