import { useState } from 'react';

export const useControlledState = (
  value: any,
  onChange?: (value: any) => void,
) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(value);

  if (typeof onChange === 'function') {
    // Controlled version
    return [value, onChange];
  }

  // Uncontrolled version
  return [uncontrolledValue, setUncontrolledValue];
};
