export const handleNumberMask = (value: any) => {
  if (typeof value === 'string') {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{6})$/, '$1.$2');
    value = value.replace(/(\d)(\d{3})$/, '$1.$2');

    return value;
  }

  return value;
};
