const isDev = process.env.NODE_ENV === 'development';

export const requiredFieldLogger = (event) => {
  if (!isDev) {
    return;
  }

  // eslint-disable-next-line
  console.log('RELAY required: ', event);
};
