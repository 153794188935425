import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/invoice';

export const invoiceRoutes: RouteType[] = [
  {
    name: 'invoice.tab',
    path: `${PATH}/tab`,
    labelHeader: ({ t }) => t('Invoices'),
    label: ({ t }) => t('Invoices'),
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredFeatures: [MODULES.INVOICE],
    routes: [
      {
        labelHeader: ({ t }) => t('Invoices'),
        label: ({ t }) => t('Invoices'),
        name: 'invoice.tab.list',
        path: `${PATH}/tab/list`,
        component: loadable(
          () => import('../../components/invoice/list/InvoiceList'),
        ),
        exact: true,
        requiredFeatures: [MODULES.INVOICE],
      },
      {
        name: 'invoice.tab.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('Tutorial'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () =>
            import(
              '../../components/integration/nfeio/tutorial/IntegrationNfeioTutorialTab'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.INVOICE],
      },
      {
        name: 'invoice.tab.settings',
        path: `${PATH}/tab/settings`,
        labelHeader: ({ t }) => t('Settings'),
        label: ({ t }) => t('Settings'),
        component: loadable(
          () =>
            import(
              '../../components/integration/nfeio/IntegrationNfeioSettings'
            ),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE],
        requiredFeatures: [MODULES.INVOICE],
      },
    ],
  },
  {
    labelHeader: ({ t }) => t('Invoice Detail'),
    label: ({ t }) => t('Invoice Detail'),
    name: 'invoice.details',
    path: `${PATH}/details/:id`,
    component: loadable(
      () => import('../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    routes: [
      {
        labelHeader: ({ t }) => t('Invoice Detail'),
        label: ({ t }) => t('Invoice Detail'),
        name: 'invoice.data',
        path: `${PATH}/details/:id/data`,
        component: loadable(
          () => import('../../components/invoice/detail/InvoiceDetail'),
        ),
        exact: true,
      },
    ],
  },
  {
    labelHeader: ({ t }) => t('Invoice Create'),
    label: ({ t }) => t('Invoice Create'),
    name: 'invoice.create',
    path: `${PATH}/create`,
    component: loadable(
      () => import('../../components/invoice/create/InvoiceCreate'),
    ),
    exact: true,
    requiredFeatures: [MODULES.INVOICE, MODULES.TEMP],
  },
];
