import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Card from '../card/Card';

import type { ReactNode } from 'react';

const BlankPageWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px 60px;
`;

const CardHeader = styled.h1`
  font-weight: normal;
  text-align: center;
`;

const BrowserIcons = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
  padding: 0;
`;

const BrowserIconsListItem = styled.li`
  display: inline-flex;
  list-style-type: none;
  flex-direction: column;
  align-items: center;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const UnsupportedBrowserWarning = (): ReactNode => {
  const { t } = useTranslation();

  return (
    <BlankPageWrapper>
      <CenteredCard>
        <CardHeader>{t('Browser not supported.')}</CardHeader>
        <BrowserIcons>
          <BrowserIconsListItem>
            <Anchor href='https://www.mozilla.org/en-US/firefox/new/'>
              Firefox
            </Anchor>
          </BrowserIconsListItem>
          <BrowserIconsListItem>
            <Anchor href='https://www.google.com/chrome/'>Chrome</Anchor>
          </BrowserIconsListItem>
          <BrowserIconsListItem>
            <Anchor href='https://www.microsoft.com/en-us/windows/microsoft-edge'>
              Edge
            </Anchor>
          </BrowserIconsListItem>
        </BrowserIcons>
      </CenteredCard>
    </BlankPageWrapper>
  );
};

export default UnsupportedBrowserWarning;
