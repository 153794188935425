import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

const PATH = '/home/qrcodes';

export const pixQrCodeRoutes = [
  {
    name: 'pixqrcodes',
    path: `${PATH}/tab`,
    component: loadable(() => import('../../../components/qrcodes/PixQrCodes')),
    exact: false,
    labelHeader: ({ t }) => t('QRCodes'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.QRCODES,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'pixqrcodes.report',
        path: `${PATH}/tab/report`,
        labelHeader: ({ t }) => t('Report'),
        label: ({ t }) => t('Report'),
        component: loadable(
          () => import('../../../components/qrcodes/report/QrCodeReport'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
          ROLES.OPEN_PIX.REPORT,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcodes.list',
        path: `${PATH}/tab/list`,
        labelHeader: ({ t }) => t('QRCodes'),
        label: ({ t }) => t('QRCodes'),
        component: loadable(
          () => import('../../../components/qrcodes/PixQrCodesList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcodes.transaction.list',
        path: `${PATH}/tab/transactions/list`,
        labelHeader: ({ t }) => t('Transactions'),
        label: ({ t }) => t('Transactions'),
        component: loadable(
          () => import('../../../components/qrcodes/PixQrCodeTransactionList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcodes.tutorial',
        path: `${PATH}/tab/tutorial`,
        labelHeader: ({ t }) => t('QRCodes'),
        label: ({ t }) => t('Tutorial'),
        component: loadable(
          () => import('../../../components/qrcodes/tutorial/QRCodeTutorial'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcodes.pix-machine-tutorial',
        path: `${PATH}/tab/maquininhas-pix`,
        labelHeader: ({ t }) => t('QRCodes'),
        label: ({ t }) => t('Pix Machines'),
        component: loadable(
          () =>
            import(
              '../../../components/pixMachine/tutorial/PixMachineTutorial'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcodes.pix-machine-list',
        path: `${PATH}/tab/impressoras`,
        labelHeader: ({ t }) => t('QRCodes'),
        label: ({ t }) => t('Printers'),
        component: loadable(
          () => import('../../../components/pixMachine/list/PixMachineList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
    ],
  },
  {
    name: 'pixqrcode',
    path: `${PATH}/qrcode/:id`,
    component: loadable(
      () => import('../../../components/qrcodes/detail/PixQrCodeDetailHeader'),
    ),
    exact: false,
    labelHeader: ({ t }) => t('QRCode'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.QRCODES,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
    routes: [
      {
        name: 'pixqrcode.detail',
        path: `${PATH}/qrcode/:id/detail`,
        labelHeader: ({ t }) => t('Detail'),
        label: ({ t }) => t('Detail'),
        component: loadable(
          () => import('../../../components/qrcodes/detail/PixQrCodeDetail'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcode.pdv.detail',
        path: `${PATH}/qrcode/:id/pdv/detail`,
        labelHeader: ({ t }) => t('PDV Detail'),
        label: ({ t }) => t('PDV Detail'),
        component: loadable(
          () => import('../../../components/qrcodes/detail/PixQrCodePDVDetail'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
      {
        name: 'pixqrcode.transaction.list',
        path: `${PATH}/qrcode/:id/transaction`,
        labelHeader: ({ t }) => t('Transactions'),
        label: ({ t }) => t('Transactions'),
        component: loadable(
          () =>
            import(
              '../../../components/qrcodes/detail/PixQrCodeTransactionListDetail'
            ),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
          ROLES.OPEN_PIX.QRCODES,
        ],
        requiredFeatures: [MODULES.OPEN_PIX],
      },
      {
        name: 'pixqrcode.debug',
        path: `${PATH}/qrcode/:id/debug`,
        labelHeader: ({ t }) => t('Debug'),
        label: ({ t }) => t('Debug'),
        component: loadable(
          () =>
            import('../../../components/qrcodes/detail/debug/PixQrCodeDebug'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.OPEN_PIX.ADMIN.ALL,
          ROLES.OPEN_PIX.USER.ALL,
        ],
        requiredFeatures: [MODULES.OPEN_PIX, MODULES.TEMP],
      },
    ],
  },
  {
    name: 'qrcode.static',
    path: `${PATH}/qrcode-static`,
    component: loadable(
      () => import('../../../components/qrcodes/form/QRCodeCreate'),
    ),
    exact: true,
    label: 'OpenPix',
    labelHeader: 'OpenPix',
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.OPEN_PIX.ADMIN.ALL,
      ROLES.OPEN_PIX.QRCODES,
      ROLES.OPEN_PIX.USER.ALL,
    ],
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];
