import type { FormikContext } from 'formik';
import { memo } from 'react';

import { useFastField } from '@woovi/hooks';

import type { TextFieldProps } from './TextField';
import TextField from './TextField';
import { handleNumberMask } from './utils';

import type { ReactNode } from 'react';

export type TextFieldFormikProps = {
  name: string;
  type: string;
} & TextFieldProps;

type Event = React.ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
>;

type Props = {
  formik: FormikContext<{ [key: string]: string }>;
  name: string;
  helperText?: string;
  label?: string;
  onChange?: (event: Event) => () => void;
  shouldResync?: boolean;
  showError?: boolean;
  handleError?: boolean;
} & TextFieldFormikProps;

const TextFieldFormik = (props: Props): ReactNode => {
  const {
    name,
    mask,
    min,
    max,
    type,
    onChange,
    label,
    helperText,
    onBlur,
    shouldResync = true,
    inputProps,
    showError,
    ...rest
  } = props;

  const [field, meta, helpers] = useFastField({ name, shouldResync });
  // const [field, , helpers] = useField(name);

  const hasError = meta?.touched;

  const getErrorProps = () => {
    if (!hasError) {
      return {};
    }

    if (typeof meta?.error === 'string') {
      return {
        error: !!meta?.error,
        helperText: meta?.error,
      };
    }

    return {};
  };

  const errorProps = getErrorProps();

  const handleChange = (event: Event) => {
    const { value } = event.target;

    if (mask) {
      switch (mask) {
        case 'NUMBER':
          helpers.setValue(handleNumberMask(value.toString()));
      }
    }

    if (type && type.toUpperCase() === 'NUMBER') {
      if (min && Number.parseInt(value, 10) < Number.parseInt(min, 10)) {
        return helpers.setValue(min);
      }

      if (max && Number.parseInt(value, 10) > Number.parseInt(max, 10)) {
        return helpers.setValue(max);
      }
    }

    if (type && type.toUpperCase() === 'NUMBER') {
      helpers.setValue(Number.parseInt(value, 10));
      onChange && onChange(event);

      return;
    }

    helpers.setValue(value);
    onChange && onChange(event);
  };

  const handleBlur = (e) => {
    field.onBlur(e);
    onBlur && onBlur(e);
  };

  const dataTestIdInputProps = {
    'data-testid': `${name}`,
  };

  const textInputProps = props.inputProps
    ? { ...dataTestIdInputProps, ...props.inputProps }
    : dataTestIdInputProps;

  return (
    <TextField
      onChange={handleChange}
      value={field.value}
      onBlur={handleBlur}
      helperText={helperText}
      label={label}
      name={name}
      inputProps={textInputProps}
      type={type}
      {...rest}
      {...errorProps}
    />
  );
};

export default memo(TextFieldFormik);
