import { graphql } from 'react-relay';

const ChargeCreatedSubscription = graphql`
  subscription ChargeCreatedSubscription(
    $input: ChargeCreatedInput!
    $connections: [ID!]!
  ) {
    ChargeCreated(input: $input) {
      charge
        @prependNode(connections: $connections, edgeTypeName: "ChargeEdge") {
        id
        createdBy {
          name
        }
        customer {
          id
          name
          email
          phone
          taxID {
            taxID
            type
          }
        }
        value
        valueWithDiscount
        valuePaid
        discount
        comment
        identifier
        transactionID
        pixLocation
        brCode
        paymentLinkID
        status
        expiration
        isRemoved
        createdAt
        manuallyAttached
        expiresDate
        additionalInfo {
          key
          value
        }
        pixTransactions(first: 1) {
          edges {
            node {
              id
              endToEndId
              ...ManuallyAttachedBanner_pixTransaction
              dispute {
                id
                ...DisputeOppenedBanner_dispute
              }
            }
          }
        }
        cashback {
          percentage
          value
          status
        }
        cashbackExclusive {
          percentage
          value
          status
        }
        cashbackExclusiveScheduled {
          edges {
            node {
              value
            }
          }
        }
        interestsSettings {
          active
          daysAfterDueDate
          interests {
            value
            type
            modality
          }
          fines {
            value
            type
          }
        }
        decodedBrCode {
          interests
          fines
          finalValue
        }
        isFromTestAccount
        company {
          hasTemp
        }
        ...ChargeDetailInstallmentCard_charge
        ...ChargeQrCodeDetailHeaderRight_charge
        ...ChargeQrCodeDetailSimulatePayment_charge
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...Payment_charge
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...ChargePixCrediaryData_charge
        ...ChargeQrCodeDetailInfoCard_charge
      }
    }
  }
`;

export { ChargeCreatedSubscription };
