import { graphql } from 'react-relay';

export const UserMasterCompanyChange = graphql`
  mutation UserMasterCompanyChangeMutation(
    $input: UserMasterCompanyChangeInput!
  ) {
    UserMasterCompanyChange(input: $input) {
      error
      me {
        id
        name
        taxID {
          taxID
        }
        emails {
          email
        }
        phoneNumbers
      }
    }
  }
`;
