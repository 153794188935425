import {
  CurrencyExchange,
  Email,
  FileOpen,
  HowToReg,
  Message,
  EditCalendar,
  Paid,
  WhatsApp,
} from '@mui/icons-material';

import { APP_EVENT_DOMAINS } from '@woovi/shared';

export const TIMELINE_APPEVENT_DOMAIN_ICON = {
  [APP_EVENT_DOMAINS.CHARGE]: <Paid fontSize='small' />,
  [APP_EVENT_DOMAINS.DISPUTE]: <Paid fontSize='small' />,
  [APP_EVENT_DOMAINS.EMAIL]: <Email fontSize='small' />,
  [APP_EVENT_DOMAINS.SMS]: <Message fontSize='small' />,
  [APP_EVENT_DOMAINS.WHATSAPP]: <WhatsApp fontSize='small' />,
  [APP_EVENT_DOMAINS.DATA_EXPORT]: <FileOpen fontSize='small' />,
  [APP_EVENT_DOMAINS.CHARGE_EDIT]: <EditCalendar fontSize='small' />,
  [APP_EVENT_DOMAINS.ACCOUNT_REGISTER]: <HowToReg fontSize='small' />,
  [APP_EVENT_DOMAINS.TRANSACTION]: <CurrencyExchange fontSize='small' />,
};
