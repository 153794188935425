import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';
import type { RouteType } from '@woovi/router';

const PATH = '/home/cashback';

export const cashbackRoutes: RouteType[] = [
  {
    name: 'cashback.create',
    path: `${PATH}/create`,
    labelHeader: ({ t }) => t('Cashback'),
    label: ({ t }) => t('Cashback'),
    component: loadable(
      () => import('../../components/rewards/cashback/create/CashbackCreate'),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CASHBACK, MODULES.TEMP],
  },
  {
    name: 'cashback.fidelity.create',
    path: `${PATH}/fidelity/create`,
    labelHeader: ({ t }) => t('Cashback Fidelity'),
    label: ({ t }) => t('Cashback Fidelity'),
    component: loadable(
      () =>
        import(
          '../../components/rewards/cashback/create/CashbackFidelityCreate'
        ),
    ),
    exact: true,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CASHBACK],
  },
  {
    name: 'cashback.details',
    path: `${PATH}/details/:id`,
    labelHeader: ({ t }) => t('Details'),
    label: ({ t }) => t('Details'),
    component: loadable(
      () => import('../../components/rewards/cashback/details/CashbackDetails'),
    ),
    exact: false,
    requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
    requiredFeatures: [MODULES.CASHBACK],
    routes: [
      {
        name: 'cashback.details.data',
        path: `${PATH}/details/:id/data`,
        labelHeader: ({ t }) => t('Data'),
        label: ({ t }) => t('Data'),
        component: loadable(
          () =>
            import('../../components/rewards/cashback/details/CashbackData'),
        ),
        exact: true,
        requiredRoles: [ADMIN_ROLE, ROLES.OPEN_PIX.ADMIN.ALL],
        requiredFeatures: [MODULES.CASHBACK],
      },
    ],
  },
];
