import { useFragment, graphql } from 'react-relay';

import { MODULES } from '@woovi/roles';

import type { FeatureTemp_user$key } from './__generated__/FeatureTemp_user.graphql';
import type { FeatureFlagProps } from './FeatureFlag';
import FeatureFlag from './FeatureFlag';

import type { ReactNode } from 'react';

type Props = {
  user: FeatureTemp_user$key;
  isTemp?: boolean;
} & Omit<FeatureFlagProps, 'user' | 'isTemp'>;

const FeatureTemp = ({ features, isTemp = true, ...props }: Props): ReactNode => {
  const user = useFragment<FeatureTemp_user$key>(
    graphql`
      fragment FeatureTemp_user on User {
        ...FeatureFlag_user
      }
    `,
    props.user,
  );

  return (
    <FeatureFlag
      features={[MODULES.TEMP]}
      isTemp={isTemp}
      {...props}
      user={user}
    />
  );
};

export default FeatureTemp;
