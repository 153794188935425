import type { ChipProps } from '@mui/material/Chip';
import _Chip from '@mui/material/Chip';
import type { ReactNode } from 'react';
import styled from 'styled-components';

const Chip = styled(_Chip)`
  margin-left: 3px;
`;

const ValueText = styled.span`
  font-weight: 600;
`;

const KeyText = styled.span`
  padding-right: 4px;
  font-weight: normal;
  border-right: 1px solid;
  margin: 2px 0;
  margin-right: 5px;
`;

export type TagProps = {
  label: string;
  value?: string | ReactNode;
  breakByColon?: boolean;
} & ChipProps;

const Tag = (props: TagProps): ReactNode => {
  const { label, value, breakByColon = true, ...rest } = props;

  if (!label) {
    return null;
  }

  const _label = () => {
    if (value) {
      return (
        <ValueText>
          <KeyText>{label}</KeyText>
          {value}
        </ValueText>
      );
    }

    if (label.indexOf(':') !== -1 && breakByColon) {
      const [key, value] = label.split(':');

      return (
        <ValueText>
          <KeyText>{key}</KeyText>
          {value}
        </ValueText>
      );
    }

    return label;
  };

  return <Chip label={_label()} {...rest} />;
};

export default Tag;
