import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay';
import { useHistory } from 'react-router';

import type { ExtractNode, FetchKeyProp } from '@woovi/relay';
import { withRelayBoundary } from '@woovi/relay';
import {
  BoxFlex,
  Timeline,
  TimelineAppEventItem,
  TIMELINE_APPEVENT_DOMAIN_ICON,
} from '@woovi/ui';
import { getAppEventTime } from '@woovi/utils';

import type { NotificationTimeline_me$key } from './__generated__/NotificationTimeline_me.graphql';
import type {
  NotificationTimeline_query$data,
  NotificationTimeline_query$key,
} from './__generated__/NotificationTimeline_query.graphql';
import type { NotificationTimelineQuery } from './__generated__/NotificationTimelineQuery.graphql';
import { routeToMain } from '../../../router/utils';

import type { ReactNode } from 'react';

type TimelineNode = ExtractNode<NotificationTimeline_query$data['appEvents']>;

type NotificationTimelineProps = {
  me: NotificationTimeline_me$key;
} & FetchKeyProp;

const NotificationTimeline = ({
  fetchKey,
  ...props
}: NotificationTimelineProps): ReactNode => {
  const history = useHistory();
  const { t } = useTranslation();

  const { me } = useFragment<NotificationTimeline_me$key>(
    graphql`
      fragment NotificationTimeline_me on Query {
        me {
          id
        }
      }
    `,
    props.me,
  );

  const response = useLazyLoadQuery<NotificationTimelineQuery>(
    graphql`
      query NotificationTimelineQuery(
        $first: Int
        $after: String
        $filters: AppEventFilter
      ) {
        ...NotificationTimeline_query
          @arguments(first: $first, after: $after, filters: $filters)
      }
    `,
    {
      first: 10,
      filters: {
        user: me?.id,
      },
    },
    {
      fetchPolicy: 'network-only',
      fetchKey,
    },
  );

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment<
    NotificationTimelineQuery,
    NotificationTimeline_query$key
  >(
    graphql`
      fragment NotificationTimeline_query on Query
      @refetchable(queryName: "NotificationTimelinePaginationQuery")
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String", defaultValue: null }
        filters: { type: "AppEventFilter" }
      ) {
        appEvents(first: $first, after: $after, filters: $filters)
          @connection(key: "NotificationTimeline_appEvents", filters: []) {
          edges {
            node {
              id
              domain
              createdAt
              time
              path
              params
              label
              color
            }
          }
        }
      }
    `,
    response,
  );

  const loadMore = () => {
    if (isLoadingNext) {
      return;
    }

    loadNext(30);
  };

  const { appEvents } = data;

  const { edges } = appEvents;

  const handleClick = (node?: TimelineNode | null) => {
    if (!node) {
      return;
    }

    if (!node.path) {
      return;
    }

    if (!node.params) {
      return history.push(routeToMain(node.path));
    }

    const paramsNormalized = JSON.parse(node.params);

    return history.push(routeToMain(node.path, paramsNormalized));
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={hasNext}
      useWindow={false}
    >
      {edges.length <= 0 && (
        <BoxFlex
          sx={{ px: 2, py: 3, justifyContent: 'center', alignItems: 'center' }}
        >
          <Typography fontWeight={700}>
            {t('Explore the platform to receive notifications.')}
          </Typography>
        </BoxFlex>
      )}
      {edges.length > 0 && (
        <Timeline>
          {edges.map((edge, index) => (
            <TimelineAppEventItem
              key={edge?.node?.id}
              color={edge?.node?.color}
              icon={TIMELINE_APPEVENT_DOMAIN_ICON[edge?.node?.domain || '']}
              title={edge?.node?.label}
              time={getAppEventTime<TimelineNode>(edge?.node)}
              onClick={
                edge?.node?.path ? () => handleClick(edge?.node) : undefined
              }
              isFirstItem={index === 0}
              isLastItem={index + 1 === edges.length}
            />
          ))}
        </Timeline>
      )}
    </InfiniteScroll>
  );
};

export default withRelayBoundary(NotificationTimeline);
