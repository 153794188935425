import { ExpandLess } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useId, useState } from 'react';

export type CollapseProps = {
  header: string | JSX.Element | React.ReactNode;
  children?: React.ReactNode;
  initialExpanded?: boolean;
};

export const Collapse = ({
  initialExpanded,
  header,
  children,
}: CollapseProps) => {
  const id = useId();
  const [expanded, setExpanded] = useState(initialExpanded);

  const handleChange = () => setExpanded(!expanded);

  return (
    <MuiAccordion
      expanded={expanded}
      elevation={0}
      square
      disableGutters
      onChange={handleChange}
      sx={{
        fontWeight: '600',
        '&:before': {
          display: 'none',
        },
      }}
    >
      <MuiAccordionSummary
        sx={{
          p: 0,
          m: 0,
          '& .MuiAccordionSummary-content': {
            m: '0 !important',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(-180deg)',
          },
        }}
        expandIcon={
          <ExpandLess
            sx={{ fontSize: '20px' }}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
          />
        }
      >
        {typeof header === 'string' ? (
          <Typography sx={{ fontWeight: '600' }}>{header}</Typography>
        ) : (
          header
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        sx={{
          m: '0 !important',
          p: 0,
        }}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};
