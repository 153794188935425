import type { SxProps } from '@mui/material/styles';

export function composeStyles(
  defaultStyle: SxProps,
  ...styles: Array<SxProps | undefined>
) {
  const initialStyle: SxProps = { ...defaultStyle };

  if (!styles) {
    return initialStyle;
  }

  // Merge each style in the order provided
  styles.forEach((style) => {
    if (!style) {
      return;
    }

    Object.keys(style).forEach((key) => {
      if (
        initialStyle[key] &&
        typeof initialStyle[key] === 'object' &&
        typeof style[key] === 'object'
      ) {
        initialStyle[key] = { ...initialStyle[key], ...style[key] };
      } else {
        initialStyle[key] = style[key];
      }
    });
  });

  return initialStyle;
}
