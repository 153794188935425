import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';

import { useWindowHelpers } from '@woovi/hooks';

import BoxFlex from '../mui/BoxFlex';

type SwipeableModalTransitionProps = {
  in: boolean;
  children: JSX.Element;
};

export const SwipeableModalTransition = forwardRef<
  HTMLDivElement,
  SwipeableModalTransitionProps
>(({ children, ...props }, ref) => {
  const { isMobile } = useWindowHelpers();

  return isMobile ? (
    <BoxFlex tabIndex={-1} ref={ref}>
      <Slide direction='up' mountOnEnter unmountOnExit {...props}>
        {children}
      </Slide>
    </BoxFlex>
  ) : (
    <BoxFlex tabIndex={-1} ref={ref}>
      <Fade mountOnEnter unmountOnExit {...props}>
        {children}
      </Fade>
    </BoxFlex>
  );
});
