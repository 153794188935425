import type { LogEvent } from 'relay-runtime';

const isDev = process.env.NODE_ENV === 'development';
const enableRelayLogs = isDev;

// TODO - improve this
export const relayTransactionLogger = (event: LogEvent) => {
  if (enableRelayLogs) {
    // eslint-disable-next-line
    console.log('RELAY: ', event);
  }

  return;
};
