import loadable from '@loadable/component';

import { MODULES } from '@woovi/roles';

import type { RouteType } from '../utils';

const PATH = '/home';

const homeRoutes: RouteType[] = [
  {
    name: 'home.main',
    path: PATH,
    labelHeader: 'Home',
    component: loadable(() => import('../../components/home/Home')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'status',
    path: `${PATH}/status`,
    labelHeader: 'Status',
    component: loadable(() => import('../../components/core/Status')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'forbidden',
    path: `${PATH}/forbidden`,
    component: loadable(() => import('../components/Forbidden')),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'timeline',
    path: `${PATH}/timeline`,
    component: loadable(
      () => import('../../components/admin/settings/CompanyTimeline'),
    ),
    exact: true,
    requiredRoles: [],
    requiredFeatures: [],
    label: ({ t }) => t('Timeline'),
  },
  {
    name: 'tutorial',
    path: `${PATH}/tutorial`,
    component: loadable(
      () => import('../../components/home/tutorial/Tutorial'),
    ),
    exact: true,
    labelHeader: ({ t }) => t('Tutorial'),
    requiredRoles: [],
    requiredFeatures: [],
  },
  {
    name: 'help',
    path: `${PATH}/help`,
    component: loadable(() => import('../../components/home/help/Help')),
    exact: true,
    label: ({ t }) => t('Help'),
    labelHeader: ({ t }) => t('Help'),
    requiredFeatures: [MODULES.OPEN_PIX],
  },
];

export default homeRoutes;
