/**
 * @generated SignedSource<<a2d62fec0d1e5832d471d4fb1e0505b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMenuAccountItemBalanceQuery$variables = {
  id: string;
};
export type UserMenuAccountItemBalanceQuery$data = {
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"UserMenuAccountItemBalance_account">;
  } | null | undefined;
};
export type UserMenuAccountItemBalanceQuery = {
  response: UserMenuAccountItemBalanceQuery$data;
  variables: UserMenuAccountItemBalanceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMenuAccountItemBalanceQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserMenuAccountItemBalance_account"
              }
            ],
            "type": "CompanyBankAccount",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMenuAccountItemBalanceQuery",
    "selections": [
      {
        "alias": "account",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "balance",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "providerFeatures",
                "storageKey": null
              }
            ],
            "type": "CompanyBankAccount",
            "abstractKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1739d83fcafbd212f03bc5959ae9f438",
    "id": null,
    "metadata": {},
    "name": "UserMenuAccountItemBalanceQuery",
    "operationKind": "query",
    "text": "query UserMenuAccountItemBalanceQuery(\n  $id: ID!\n) {\n  account: node(id: $id) {\n    __typename\n    ... on CompanyBankAccount {\n      ...UserMenuAccountItemBalance_account\n    }\n    id\n  }\n}\n\nfragment UserMenuAccountItemBalance_account on CompanyBankAccount {\n  balance\n  code\n  ...useProviderFeatures_account\n}\n\nfragment useProviderFeatures_account on CompanyBankAccount {\n  providerFeatures\n}\n"
  }
};
})();

(node as any).hash = "3e5449b0a71c0a3eaedb58676f632771";

export default node;
