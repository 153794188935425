import type { ReactNode } from 'react';
import { createContext } from 'react';

export type ShowSnackbarArgs = {
  onActionClick?: Function | null;
  message: string;
  action?: ReactNode[];
  duration?: number;
};

export type showSnackbarFn = (args: ShowSnackbarArgs) => void;

export type SnackbarContextProps = {
  showSnackbar: showSnackbarFn;
};

const SnackbarContext = createContext({
  // eslint-disable-next-line
  showSnackbar: (args: ShowSnackbarArgs) => {},
});

export default SnackbarContext;
