import type { GridColTypeDef } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';

export type CellTypeBooleanProps = {
  value?: boolean;
};

export const CellTypeBoolean = ({ value }: CellTypeBooleanProps) => {
  const { t } = useTranslation();

  if (typeof value !== 'boolean') {return '-';}

  if (value) {return t('Yes');}

  return t('No');
};

export const cellTypeBoolean: GridColTypeDef = {
  extendType: 'string',
  renderCell: ({ value }) => <CellTypeBoolean value={value} />,
};

export const GRID_BOOLEAN_CUSTOM_COL_DEF: GridColTypeDef = {
  type: 'boolean',
  renderCell: ({ value }) => <CellTypeBoolean value={value} />,
}
