import { parsePhoneNumber as _parsePhoneNumber } from 'libphonenumber-js';

export const parsePhoneNumber = (phoneNumber: string) => {
  try {
    const parsedPhoneNumber = _parsePhoneNumber(phoneNumber, 'BR');

    if (!parsedPhoneNumber.isValid()) {
      return null;
    }

    return parsedPhoneNumber;
  } catch (_err) {
    return null;
  }
};
