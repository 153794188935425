import loadable from '@loadable/component';

import { ADMIN_ROLE, ROLES } from '@woovi/roles';

import type { RouteType } from '../../utils';
import { EXPORT_ROUTE_MODULES } from '../core/settingsRoutes';

const PATH = '/home/me';

const EXPORT_ROUTE_ROLES = [
  ADMIN_ROLE,

  ROLES.OPEN_PIX.ADMIN.ALL,
  ROLES.OPEN_PIX.CHARGE_EXPORT,
  ROLES.OPEN_PIX.TRANSACTIONS_EXPORT,

  ROLES.PLANNING.ALL,
];

const meRoutes: RouteType[] = [
  {
    name: 'me.account',
    path: `${PATH}/account`,
    labelHeader: 'Minha Conta',
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
    routes: [
      {
        name: 'me.account.edit',
        path: `${PATH}/account/edit`,
        labelHeader: 'Editar Meus Dados',
        requiredRoles: [],
        requiredFeatures: [],
        exact: false,
        component: loadable(() => import('../../../components/user/me/MeEdit')),
        label: 'Dados',
      },
      {
        name: 'me.account.exports',
        path: `${PATH}/account/exports`,
        labelHeader: ({ t }) => t('Exports'),
        label: ({ t }) => t('Exports'),
        component: loadable(
          () => import('../../../components/dataExport/ExportList'),
        ),
        exact: false,
        requiredRoles: [...EXPORT_ROUTE_ROLES],
        requiredFeatures: [...EXPORT_ROUTE_MODULES],
      },
      {
        name: 'me.session',
        path: `${PATH}/account/sessions`,
        labelHeader: ({ t }) => t('Sessions'),
        label: ({ t }) => t('Sessions'),
        requiredRoles: [],
        requiredFeatures: [],
        exact: true,
        component: loadable(
          () => import('../../../components/user/me/MeSession'),
        ),
      },
    ],
  },
  {
    name: 'me.logout',
    path: `${PATH}/logout`,
    component: loadable(
      () => import('../../../components/user/me/logout/MeLogout'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [],
  },
];

export default meRoutes;
