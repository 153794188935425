import { ArrowOutward } from '@mui/icons-material';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ITimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import type { Moment } from 'moment';
import moment from 'moment';

import type { TimelineColors, TimelineItemProps } from './TimelineItem';
import Card from '../card/Card';
import BoxFlex from '../mui/BoxFlex';

import type { ReactNode } from 'react';

type TimelineAppEventItemProps = {
  title?: string | null;
  time: string | Date | Moment;
  onClick?: () => void;
} & TimelineItemProps;

export const TimelineAppEventItem = (
  {
    title,
    time,
    color = 'primary',
    icon,
    onClick,
    isFirstItem = false,
    isLastItem = false,
  }: TimelineAppEventItemProps,
): ReactNode => {
  return (
    <ITimelineItem
      sx={{
        ':hover': {
          cursor: onClick ? 'pointer' : '',
        },
      }}
      onClick={onClick}
    >
      <TimelineSeparator>
        <TimelineConnector
          sx={{ backgroundColor: isFirstItem ? 'transparent' : 'default' }}
        />
        <TimelineDot
          color={!color ? 'primary' : (color as TimelineColors)}
          sx={{ boxShadow: 'none' }}
        >
          {icon}
        </TimelineDot>
        <TimelineConnector
          sx={{ backgroundColor: isLastItem ? 'transparent' : 'default' }}
        />
      </TimelineSeparator>
      <TimelineContent
        sx={{ display: 'flex', alignItems: 'center', pr: 0, pl: '8px' }}
      >
        <Card sx={{ p: 1, flex: 1 }}>
          <BoxFlex sx={{ justifyContent: 'space-between' }}>
            <BoxFlex sx={{ flexDirection: 'column' }}>
              <Typography fontWeight='bold'>{title}</Typography>
              <Typography color='GrayText'>
                {moment(time).format('DD-MM-YYYY HH:mm')}
              </Typography>
            </BoxFlex>
            {onClick && <ArrowOutward fontSize='small' color='action' />}
          </BoxFlex>
        </Card>
      </TimelineContent>
    </ITimelineItem>
  );
};
