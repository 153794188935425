import loadable from '@loadable/component';

import { ADMIN_ROLE, MODULES, ROLES } from '@woovi/roles';

import type { RouteType } from '../../utils';

const PATH = '/home/areas';

const costRevenueCenter: RouteType[] = [
  {
    name: 'areas.add',
    path: `${PATH}/area-create`,
    labelHeader: ({ t }) => t('Areas/Stores - Add'),
    component: loadable(() => import('../../../components/area/add/AreaAdd')),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
  },
  {
    name: 'areas.tutorial',
    path: `${PATH}/tutorial`,
    labelHeader: ({ t }) => t('Areas'),
    component: loadable(
      () => import('../../../components/area/tutorial/AreaTutorial'),
    ),
    exact: true,
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
  },
  {
    name: 'areas',
    path: `${PATH}/tab`,
    component: loadable(() => import('../../../components/area/AreaHeader')),
    exact: false,
    labelHeader: ({ t }) => t('Planning Areas or Stores'),
    requiredRoles: [
      ADMIN_ROLE,
      ROLES.PLANNING.ALL,
      ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
    ],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'areas.list',
        path: `${PATH}/tab/list`,
        labelHeader: ({ t }) => t('Areas/Stores'),
        component: loadable(
          () => import('../../../components/area/list/AreaList'),
        ),
        exact: false,
        label: ({ t }) => t('List'),
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
      },
    ],
  },
  {
    name: 'planning.myCostRevenueCenters',
    path: `${PATH}/planning/myAreas`,
    labelHeader: ({ t }) => t('My Areas/Stores'),
    component: loadable(
      () => import('../../../components/common/content/TabbedContentSimple'),
    ),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.TEMP],
    routes: [
      {
        name: 'planning.myCostRevenueCenters',
        path: `${PATH}/planning/myAreas`,
        labelHeader: ({ t }) => t('My Areas/Stores'),
        component: loadable(
          () => import('../../../components/area/MyCostRevenueCenterList'),
        ),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.TEMP],
      },
    ],
  },
  {
    name: 'areas.view',
    path: `${PATH}/detail/:id`,
    labelHeader: ({ t }) => t('Areas/Stores - Details'),
    component: loadable(() => import('../../../components/area/detail/Area')),
    exact: false,
    requiredRoles: [],
    requiredFeatures: [MODULES.PLANNING],
    routes: [
      {
        name: 'areas.charges',
        path: `${PATH}/detail/:id/charges`,
        labelHeader: ({ t }) => t('Areas/Stores - Details'),
        component: loadable(
          () => import('../../../components/area/AreaChargeList'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
        label: ({ t }) => t('Charges'),
      },
      {
        name: 'areas.edit',
        path: `${PATH}/detail/:id/edit`,
        labelHeader: ({ t }) => t('Areas/Stores - Details'),
        component: loadable(
          () => import('../../../components/area/adjust/CostRevenueCenterEdit'),
        ),
        exact: true,
        requiredRoles: [
          ADMIN_ROLE,
          ROLES.PLANNING.ALL,
          ROLES.PLANNING.COST_REVENUE_CENTER.ALL,
        ],
        requiredFeatures: [MODULES.PLANNING],
        label: ({ t }) => t('Settings'),
      },
      {
        name: 'areas.users',
        path: `${PATH}/detail/:id/users`,
        labelHeader: ({ t }) => t('Users'),
        component: loadable(() => import('../../../components/area/AreaUsers')),
        exact: true,
        requiredRoles: [],
        requiredFeatures: [MODULES.PLANNING],
        label: ({ t }) => t('Users'),
      },
    ],
  },
];

export default costRevenueCenter;
